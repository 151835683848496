import JoinnLogoBackground from "@/assets/mastercard/joinn-logo.svg";
import McLogo from "@/assets/mastercard/mc-logo.svg";
import { Spinner, Tooltip } from "@/components";

import { Button, Card } from "@/components/ui";
import { useAccounts, useDashboard, useIcons, useMastercard } from "@/hooks";
import { BackCard, CardHeader, FrontCard } from "@/pages/Dashboard/Mastercard";
import { useSubmitSimulatorAuthorizationMutation } from "@/redux/immersve/immersve.simulator.api";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { useState } from "react";

export default function Mastercard() {
  const { toggleModal } = useModalState();
  const { HideIcon, ShowIcon } = useIcons();
  const { isConnected, isConnecting } = useImmersveState();
  const { spendingAccount } = useAccounts();
  const { cardDetails, secretData } = useMastercard();
  const { isAddressLinkedAccount } = useDashboard();

  const [isHovering, setIsHovering] = useState(false);
  const [isFrontCard, setIsFrontCard] = useState(true);
  const [simulate] = useSubmitSimulatorAuthorizationMutation();

  async function handlePay() {
    if (!secretData.data) {
      console.error("No card details found");
      return;
    }
    simulate({
      transactionType: "purchase",
      transactionAmount: "1",
      currencyCode: "USD",
      cardPan: secretData.data?.pan,
      cardExpiry: secretData.data?.expiry,
      cardCvv: secretData.data?.cvv2,
    });
  }

  async function revealCardDetails() {
    if (isFrontCard) {
      await secretData.get();
      setIsFrontCard(false);
      return;
    }
    setIsFrontCard(true);
  }

  const expiryDate = cardDetails.data?.expiry;
  const expiryDateFormatted = expiryDate
    ? `${expiryDate.slice(4, 6)}/${expiryDate.slice(2, 4)}`
    : undefined;

  return (
    <Card
      radius="md"
      size="md"
      className="h-full w-auto content-center max-[360px]:border-none max-[360px]:p-0"
    >
      <div
        className="relative flex h-48 w-80 items-center justify-center rounded-2xl bg-[hsl(203,89%,53%)] p-4 max-[360px]:w-full"
        onMouseEnter={() => {
          if (isConnected) {
            setIsHovering(true);
          }
        }}
        onMouseLeave={() => setIsHovering(false)}
        aria-hidden="true"
      >
        <img
          src={JoinnLogoBackground}
          alt="Joinn Logo"
          className="absolute z-0"
        />
        <div className="z-10 flex h-full w-full flex-col justify-between">
          <div className="flex items-start justify-between">
            <CardHeader
              isFrontCard={isFrontCard}
              expiry={expiryDateFormatted}
              isConnected={isConnected}
              secretData={secretData.data}
            />
            <img src={McLogo} alt="Mastercard Logo" />
          </div>
          <div>
            <div className="flex items-end justify-between">
              {isFrontCard ? (
                <FrontCard
                  balance={spendingAccount?.balance}
                  panLast4={cardDetails.data?.panLast4}
                  isHidden={isHovering}
                  isConnected={isConnected}
                  isLoading={cardDetails.isLoading || isConnecting}
                />
              ) : (
                <BackCard
                  pan={secretData.data?.pan}
                  cvv2={secretData.data?.cvv2}
                  expiry={expiryDateFormatted}
                  isLoading={secretData.isLoading}
                />
              )}
            </div>
            {isHovering && (
              <div className="gap mt-2 flex items-center gap-3">
                <Button
                  variant="primary"
                  className="w-full py-2 hover:bg-card"
                  onClick={revealCardDetails}
                  disabled={secretData.isLoading}
                >
                  {isFrontCard ? <ShowIcon /> : <HideIcon />}
                  {`${isFrontCard ? "Display" : "Hide"} details`}
                  {secretData.isLoading && <Spinner />}
                </Button>
                <Tooltip
                  content={
                    isAddressLinkedAccount
                      ? "Please switch to Master Wallet to enable cancellation of Matercard"
                      : ""
                  }
                >
                  <div>
                    <Button
                      variant="primary"
                      className="px-4 py-2 hover:bg-card"
                      disabled={isAddressLinkedAccount}
                      onClick={() =>
                        toggleModal({
                          title: "Cancel Mastercard",
                          id: "cancel-mc",
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
