import { useDashboard } from "@/hooks";
import { getMaskedAddress } from "@/lib/utils";
import { Text } from "../typography";
import { Card } from "../ui";
import { DataTile } from "./dataTile";

export function VaultSection() {
  const { info } = useDashboard();

  const vaults = info?.vaults ?? [];

  if (!vaults) return null;

  // TODO: add missing values
  return (
    <>
      {vaults?.map((vault) => (
        <Card radius="md" size="md">
          <div key={vault.address}>
            <div>
              <Text size="lg" variant="accent">
                {vault.name}
              </Text>
              <Text size="sm" variant="muted">
                {getMaskedAddress(vault.address)}
              </Text>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <DataTile title="APY" value={"n/a"} />
              <DataTile title="TVL" value={"n/a"} />
              <DataTile title="Total Users" value={"n/a"} />
              <DataTile title="Sponsored Gas" value={"n/a"} />
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}
