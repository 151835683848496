import { ImmersveStageStatus } from "@/const";
import { UserProfile } from "@/services/interfaces";
import { HighlightedTextProps, BtnVariants } from "@/types";
import { type ClassValue, clsx } from "clsx";
import { isEmpty } from "lodash";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const scrollIntoElement = (
  elementId: string,
  options?: ScrollIntoViewOptions,
) => {
  const block = options?.block || "start";
  const inline = options?.inline || "nearest";

  const element = document.getElementById(elementId);
  element?.scrollIntoView({
    behavior: "smooth",
    block,
    inline,
  });
};

/**
 *
 * @param address
 * @param index
 * @returns
 */
export const getMaskedAddress = (
  address?: string | null,
  index = 6,
  mask = "....",
) => {
  if (!address) return "n/a";
  return `${address.slice(0, index)}${mask}${address.slice(-index)}`;
};

/**
 *
 * @param key
 * @returns
 */
export function parseCookie(key: string) {
  const attributes =
    typeof window !== "undefined" && document.cookie.split(`; ${key}=`);

  if (attributes && attributes.length === 2) {
    const value = attributes?.pop()?.split(";")?.shift();

    return value;
  }
}

export function getHighlightedTexts(
  highlights: HighlightedTextProps[],
  content: string,
) {
  const accents = highlights.map((text) => {
    return text.label;
  });

  const pattern = RegExp(`(${accents.join("|")})`, "g");
  return content.split(pattern);
}

export function numberWithCommas(value: number | string) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeCommaSeparator(value: string) {
  return value.replace(/,/g, "");
}

export function hasBaseUrl(url?: string) {
  if (url) {
    return RegExp(/http/).exec(url);
  }

  return false;
}

export function convertToCurrency(inputValue: string, decimalLength = 5) {
  if (inputValue === undefined) return;
  const inputValueToManipulate = inputValue.replaceAll(",", "");
  const integers = inputValueToManipulate
    .split(".")[0]
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimals = inputValueToManipulate.split(".")[1];

  if (
    (isNaN(Number(inputValueToManipulate)) && inputValue !== ".") ||
    (decimals && decimals.length > decimalLength)
  )
    return inputValue.slice(0, -1);

  if (decimals) {
    return integers + "." + decimals;
  }

  if (!decimals && inputValueToManipulate.includes(".")) {
    return integers + ".";
  }
  return integers;
}

export function getErrorMessage(e: unknown) {
  const error = e as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  if (!isEmpty(RegExp(/Details:/).exec(String(e))?.input)) {
    const match = String(error).split(RegExp(/Details:/));
    return match[0];
  } else if (error?.message) {
    return error.message;
  } else if (error.data?.message) {
    return error.data?.message;
  }

  return String(error);
}

export function routeToUrl(url: string) {
  if (window) {
    window.open(url, "_blank");
  }
}

export function getKycBtnVariant(status: string) {
  let variant = "primary";

  if (status === ImmersveStageStatus.PENDING) {
    variant = "pending";
  } else if (status === ImmersveStageStatus.BLOCKED) {
    variant = "error";
  } else if (status === ImmersveStageStatus.OK) {
    variant = "success";
  }

  return variant as BtnVariants;
}

export function formatNumberWithComma(
  value: number | string,
  options?: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  },
) {
  const amount = parseFloat(value.toString());

  const formatted = new Intl.NumberFormat("en-AU", {
    maximumFractionDigits: options?.maximumFractionDigits ?? 2,
    minimumFractionDigits: options?.minimumFractionDigits ?? 2,
  }).format(Number(amount.toFixed(2)));

  return formatted;
}

export function sanitizeNumber(value: string) {
  const strDecimals = value.split(".");

  if (strDecimals.length > 1) {
    const wholeNum = numberWithCommas(Number(strDecimals[0]));
    const decimals = strDecimals[1];

    return `${wholeNum}.${decimals}`;
  } else if (Number(value)) {
    return numberWithCommas(Number(value));
  } else {
    return "";
  }
}

export function isUserOnboardingCompleted(user?: UserProfile) {
  return (
    !!user?.bofPrivacyPolicy &&
    !!user?.bofTermsOfService &&
    !!user?.dataShareAgreement &&
    !!user?.nonUSPersonDeclaration &&
    !!user?.countryOfResidence
  );
}

export function isJoinnVerified(user?: UserProfile) {
  return (
    isUserOnboardingCompleted(user) && !!user?.emailVerification?.isVerified
  );
}

export function getUnitLabel(value: string | number) {
  return Number(value) > 1 ? "Units" : "Unit";
}
