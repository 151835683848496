import { useToast } from "@/components/hooks/use-toast";
import { Text } from "@/components/typography";
import { Button, Separator } from "@/components/ui";
import { useDashboard, useLogin, useLogout } from "@/hooks";
import { getErrorMessage } from "@/lib/utils";
import {
  useGenerateChallengeMutation,
  useLinkAccountMutation,
  useSwitchAccountMutation,
} from "@/redux/auth/auth.api";
import { useUser } from "@/redux/user/user.slice";
import { AxiosError } from "axios";
import { useRef } from "react";
import { useAccount, useSignMessage } from "wagmi";

export function AddWallet() {
  const [generateChallenge] = useGenerateChallengeMutation();
  const [linkAccount] = useLinkAccountMutation();
  const [switchAccount] = useSwitchAccountMutation();
  const isProceedingRef = useRef(false);
  const { address, chainId } = useAccount();
  const { disconnect } = useLogout();
  const { lastLoggedInWallet } = useUser();
  const { refetch } = useDashboard();
  const { signAccess, setupAxiosInstance } = useLogin();
  const { signMessageAsync } = useSignMessage();
  const { toast } = useToast();

  async function handleLogin() {
    if (!address) return;

    if (isProceedingRef.current) return;
    isProceedingRef.current = true;

    try {
      if (!chainId) return;
      await signAccess({ address, chainId });
      await refetch();
    } catch (error) {
      const message = getErrorMessage(error);
      toast({
        description: message,
        variant: "error",
      });
    } finally {
      isProceedingRef.current = false;
    }
  }

  async function handleLinkAddress() {
    if (isProceedingRef.current) return;
    isProceedingRef.current = true;

    try {
      if (!lastLoggedInWallet) return;
      if (!chainId) return;
      if (!address) return;
      const challengeResp = await generateChallenge({
        address,
        chainId: chainId.toString(),
      });

      if (!challengeResp.data) {
        throw new Error("Invalid response from generateChallenge");
      }

      const signature = await signMessageAsync({
        message: challengeResp.data.message,
      });

      await linkAccount({
        address,
        nonce: challengeResp.data.nonce,
        signature: signature,
      }).unwrap();

      const { data } = await switchAccount(address);
      if (!data) {
        throw new Error("Invalid response from switchAccount");
      }
      setupAxiosInstance(data.accessToken);
      localStorage.setItem("refreshToken", data.accessToken);
      await refetch();
    } catch (error) {
      console.error("error", error);
      if (error instanceof AxiosError) {
        return toast({
          description: error.response?.data?.error || "Unknown Axios error",
          variant: "error",
        });
      }

      const message = getErrorMessage(error);

      toast({
        description: message,
        variant: "error",
      });
    } finally {
      isProceedingRef.current = false;
    }
  }

  return (
    <div className="m-5 flex grow flex-col items-center gap-7">
      <div className="flex w-full flex-col gap-10">
        <Text size="2xl" className="font-normal">
          Welcome to Joinn.io!
        </Text>
        <Text size="md">
          Hi there, you have been detected using a new wallet address. Please
          select an option to continue setting up your account!
        </Text>
      </div>
      <div className="flex w-full flex-col gap-3">
        <Button
          type="button"
          onClick={handleLogin}
          variant="dark"
          className="justify-start gap-5 p-5"
          disabled={isProceedingRef.current}
        >
          Continue as a new User.
        </Button>
        <div className="flex items-center gap-2">
          <Separator orientation="horizontal" className="shrink" />
          <Text size="md">or</Text>
          <Separator orientation="horizontal" className="shrink" />
        </div>
        <Button
          variant="dark"
          className="justify-start gap-5 p-5"
          type="button"
          onClick={handleLinkAddress}
          disabled={isProceedingRef.current}
        >
          Link this new wallet address to your existing Joinn Account.
        </Button>
      </div>
      <Text size="md" variant="accent">
        To return to your Joinn profile please connect the previous wallet or
        any other wallet that is already connected to your profile.
      </Text>
      <Button
        type="button"
        className="self-end font-normal"
        onClick={disconnect}
      >
        Logout
      </Button>
    </div>
  );
}
