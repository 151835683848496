import Pencil from "@/assets/icons/pencil.svg";
import Star from "@/assets/icons/star.svg";
import { CopyButton, Dropdown, Text, Tooltip } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { useIcons, useLogout } from "@/hooks";
import { formatNumberWithComma, getMaskedAddress } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { EllipsisVertical } from "lucide-react";

type ExternalWalletProps = {
  accountBalance: string;
  chainWalletBalance: string;
  isMasterWallet: boolean;
  isWalletConnected: boolean;
  walletAddress: string;
  walletName: string;
};

export function ExternalWallet({
  accountBalance,
  chainWalletBalance,
  isMasterWallet,
  isWalletConnected,
  walletAddress,
  walletName,
}: Readonly<ExternalWalletProps>) {
  const { disconnect } = useLogout();
  const { toggleModal } = useModalState();
  const { InfoIcon2 } = useIcons();

  function handleDisconnectWallet() {
    toggleModal({
      id: "disconnect-wallet",
      data: { walletAddress, walletName },
    });
  }

  async function handleLogout() {
    await disconnect();
  }

  function handleWalletNameChange() {
    toggleModal({
      title: "Change Wallet Name",
      id: "change-wallet-name",
      data: { walletAddress, walletName },
    });
  }

  return (
    <Card variant="secondary" className="py-4">
      <div className="flex flex-col items-end justify-between gap-2 sm:flex-row sm:gap-4">
        <div className="relative w-full sm:w-1/3">
          <div className="flex items-center gap-2">
            {isWalletConnected && (
              <Tooltip content="Connected wallet" side="bottom">
                <div className="h-3 w-3 rounded-full bg-[hsl(101,100%,50%,100%)]" />
              </Tooltip>
            )}
            {isMasterWallet && (
              <Tooltip content="Master wallet" side="bottom">
                <img src={Star} alt="Star Icon" />
              </Tooltip>
            )}
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex grow items-center justify-between">
              <Text size="lg" className="font-semibold">
                {walletName || "Wallet Name"}
              </Text>
              <Button
                variant="ghost"
                onClick={handleWalletNameChange}
                className="min-h-4 min-w-4"
              >
                <img src={Pencil} alt="Pencil Icon" className="opacity-50" />
              </Button>
            </div>
          </div>
          <div className="flex grow items-center justify-end gap-5 max-[470px]:justify-between">
            <div className="flex grow items-center justify-between">
              <Text size="md" className="font-normal">
                {getMaskedAddress(walletAddress, 4)}
              </Text>
              <CopyButton text={walletAddress} className="opacity-50" />
            </div>
          </div>
        </div>
        <Separator orientation="vertical" className="hidden h-8 sm:block" />
        <div className="w-full sm:w-1/3">
          <Text size="lg" className="font-normal">
            {formatNumberWithComma(accountBalance ?? 0)} USDC
          </Text>
          <Tooltip
            content="Combined value of assets across all Joinn Earn Accounts. Excludes Spending Account."
            side="bottom"
          >
            <div className="flex items-center gap-2">
              <Text variant="accent">Portfolio Value</Text>
              <InfoIcon2 />
            </div>
          </Tooltip>
        </div>
        <Separator orientation="vertical" className="hidden h-8 sm:block" />
        <div className="flex w-full items-center justify-between sm:w-1/3">
          <div>
            <Text size="lg" className="font-normal">
              {formatNumberWithComma(chainWalletBalance ?? 0)} USDC
            </Text>
            <Tooltip
              content="The amount of USDC available in this wallet on the connected network."
              side="bottom"
            >
              <div className="flex items-center gap-2">
                <Text variant="accent"> Available USDC</Text>
                <InfoIcon2 />
              </div>
            </Tooltip>
          </div>
          <Dropdown
            triggerProps={{ arrow: false }}
            className="min-w-48"
            options={[
              {
                id: "1",
                label: isMasterWallet ? "Logout" : "Disconnect wallet",
              },
            ]}
            label={<EllipsisVertical />}
            onSelectOption={() => {
              if (isMasterWallet) handleLogout();
              else handleDisconnectWallet();
            }}
          />
        </div>
      </div>
    </Card>
  );
}
