import {
  useGenerateChallengeMutation,
  useGenerateTokenMutation,
  useReGenerateTokenMutation,
} from "@/redux/auth/auth.api";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { axiosInstance } from "@/services/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignMessage } from "wagmi";
import { useLogout } from "./useLogout";

export function useLogin() {
  const user = useUser();
  const {
    logInUser,
    isUserLoggedIn,
    setLastLoggedInWallet,
    lastLoggedInWallet,
  } = user;
  const { disconnect } = useLogout();
  const { signMessageAsync } = useSignMessage();
  const [generateChallenge] = useGenerateChallengeMutation();
  const [generateToken] = useGenerateTokenMutation();
  const [reGenerateToken] = useReGenerateTokenMutation();
  const location = useLocation();

  const navigate = useNavigate();

  type SignAccessProps = {
    address: string | undefined;
    chainId: number | undefined;
  };

  const setupAxiosInstance = (token: string) => {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  const reAuth = async (
    address: string,
    refreshToken: string,
  ): Promise<string | null> => {
    try {
      const response = await reGenerateToken(refreshToken).unwrap();
      localStorage.setItem("refreshToken", response.refreshToken);
      setupAxiosInstance(response.accessToken);
      grantAccess({ address });
      return response.accessToken;
    } catch (error) {
      console.error("Re-auth failed:", error);
      localStorage.removeItem("refreshToken");
      disconnect();
      return null;
    }
  };

  type GrantAccessProps = {
    address: string;
  };

  function grantAccess({ address }: GrantAccessProps) {
    setLastLoggedInWallet(address);
    if (!isUserLoggedIn) {
      logInUser();
      navigate(Routes.DASHBOARD);
    }
  }

  async function signAccess({ address, chainId }: SignAccessProps) {
    if (!address) return;
    if (!chainId) return;

    const { message, nonce } = await generateChallenge({
      address,
      chainId: chainId.toString(),
    }).unwrap();
    const signature = await signMessageAsync({
      message: message,
    });
    const { accessToken, refreshToken } = await generateToken({
      address,
      nonce,
      signature,
    }).unwrap();

    logInUser();
    setupAxiosInstance(accessToken);
    grantAccess({ address });

    navigate(Routes.DASHBOARD);
  }

  return {
    signAccess,
    grantAccess,
    reAuth,
    setupAxiosInstance,
  };
}
