import { config } from "@/config";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

export const setupInterceptors = (
  reAuth: (address: string, refreshToken: string) => Promise<string | null>,
  getAddress: () => string | undefined,
) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const refreshToken = localStorage.getItem("refreshToken");
        const address = getAddress();

        if (!refreshToken || !address) return Promise.reject(error);

        if (isRefreshing) {
          return new Promise((resolve) => {
            refreshSubscribers.push((newToken) => {
              error.config.headers["Authorization"] = `Bearer ${newToken}`;
              resolve(axiosInstance(error.config));
            });
          });
        }

        isRefreshing = true;

        try {
          const newAccessToken = await reAuth(address, refreshToken);

          if (newAccessToken) {
            axiosInstance.defaults.headers.common["Authorization"] =
              `Bearer ${newAccessToken}`;
            refreshSubscribers.forEach((cb) => cb(newAccessToken));
            refreshSubscribers = [];
          } else {
            throw new Error("No new access token");
          }

          return axiosInstance(error.config);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          localStorage.removeItem("refreshToken");
          refreshSubscribers = [];
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    },
  );
};
