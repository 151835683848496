import { useImmersve } from "@/hooks";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { useAuth } from "@futureverse/auth-react";
import { useNavigate } from "react-router-dom";
import { useDisconnect } from "wagmi";

export function useLogout() {
  const { signOut } = useAuth();
  const { logOutUser } = useUser();
  const {
    action: { logout },
  } = useImmersve();
  const { resetSpendingHiddenState } = useImmersveState();
  const { disconnectAsync } = useDisconnect();
  const navigate = useNavigate();

  async function handleDisconnect() {
    // immersve
    logout();

    // wallet
    await disconnectAsync();
    resetSpendingHiddenState();

    // futureverse - root network
    signOut();

    // reset global state
    logOutUser();
    localStorage.removeItem("refreshToken");
    navigate(Routes.CONNECT);
  }

  return {
    disconnect: handleDisconnect,
  };
}
