import { useLogin, useSpending } from "@/hooks";
import { Login } from "@/pages";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useUser } from "@/redux/user/user.slice";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import RootLayout from "./RootLayout";
import { useAccount } from "wagmi";
import { setupInterceptors } from "@/services/axiosInstance";

export default function PrivateLayout() {
  const { isUserLoggedIn } = useUser();
  const { isConnected: isImmersveConnected } = useImmersveState();
  const { refetchFundingSource, hasFundingSource } = useSpending();
  const location = useLocation();
  const REFRESH_INTERVAL = 15000;

  const { reAuth } = useLogin();
  const { address } = useAccount();

  useEffect(() => {
    setupInterceptors(reAuth, () => address);
  }, [reAuth, address]);

  useEffect(() => {
    if (!isImmersveConnected || !hasFundingSource) return;
    const interval = setInterval(() => {
      refetchFundingSource();
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [isImmersveConnected, hasFundingSource]);

  return isUserLoggedIn ? <RootLayout /> : <Login />;
}
