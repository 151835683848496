import { WalletProvider } from "@/const";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../baseApi";
import { RootState } from "../store";

export interface UserState {
  isUserLoggedIn: boolean;
  walletProvider: WalletProvider;
  lastLoggedInWallet?: string;
}

const initialState: UserState = {
  walletProvider: WalletProvider.EOA,
  isUserLoggedIn: false,
};

export const userState = createSlice({
  name: "user",
  initialState,
  reducers: {
    logInUser: (state) => {
      state.isUserLoggedIn = true;
    },
    setWalletProvider: (state, action) => {
      state.walletProvider = action.payload;
    },
    setLastLoggedInWallet: (state, action) => {
      state.lastLoggedInWallet = action.payload;
    },
    /**
     * Removes accessToken from the localStorage
     * @param state
     */
    logOutUser: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const useUser = () => {
  const dispatch = useDispatch();
  const { logInUser, logOutUser, setWalletProvider, setLastLoggedInWallet } =
    userState.actions;

  const isUserLoggedIn = useSelector(
    (state: RootState) => state.userState.isUserLoggedIn,
  );
  const walletProvider = useSelector(
    (state: RootState) => state.userState.walletProvider,
  );
  const lastLoggedInWallet = useSelector(
    (state: RootState) => state.userState.lastLoggedInWallet,
  );

  return {
    logInUser: () => dispatch(logInUser()),
    logOutUser: () => {
      dispatch(logOutUser());
      dispatch(api.util.resetApiState());
    },
    setWalletProvider: (walletProvider: WalletProvider) =>
      dispatch(setWalletProvider(walletProvider)),
    setLastLoggedInWallet: (wallet: string) =>
      dispatch(setLastLoggedInWallet(wallet)),
    isUserLoggedIn,
    lastLoggedInWallet,
    walletProvider,
  };
};
