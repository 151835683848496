import { VAULT_ABI } from "@/abis";
import { config } from "@/chains/config";
import { MulticallAddresses, TOKEN_ADDRESSES } from "@/const";
import {
  assignResponseToContracts,
  transformBalanceObj,
} from "@/lib/balance.utils";
import {
  BalanceRequest,
  BalancesResponse,
  ContractType,
} from "@/services/interfaces";
import { multicall } from "@wagmi/core";
import { Abi, Address, erc20Abi } from "viem";
import { api } from "../baseApi";

export enum Identifier {
  VAULT = "vault",
  WALLET = "wallet",
  SPENDING_ACCOUNT = "spendingAccount",
}

export const balanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    balances: builder.query<BalancesResponse, BalanceRequest>({
      queryFn: async (props) => {
        const { vaults, joinnWalletAdddress, chainId, userWallets } = props;

        if (!chainId) {
          return { data: { vaults: [], wallets: [] } };
        }

        const usdcToken = TOKEN_ADDRESSES[chainId.toString()].USDC;

        const vaultsAbiFunctions = [
          { functionName: "decimals" },
          { functionName: "pricePerShare" },
          {
            functionName: "balanceOf",
            args: [joinnWalletAdddress as Address],
          },
        ];

        const vaultsContracts = vaults
          .map((vault) => {
            return vaultsAbiFunctions.map((func) => {
              return {
                address: vault.address as Address,
                abi: VAULT_ABI as Abi,
                metadata: {
                  identifier: Identifier.VAULT,
                  address: vault.address,
                  name: vault.name,
                  symbol: vault.symbol,
                  strategyAddress: vault.strategyAddress,
                },
                ...func,
              };
            });
          })
          .flat();

        const walletsAbiFunctions = [
          { functionName: "decimals" },
          {
            functionName: "balanceOf",
            args: (owner: Address) => [owner],
          },
        ];

        const joinnWallets = Object.values(userWallets)
          .map((wallet) => {
            if (!wallet) return [];

            return walletsAbiFunctions.map((func) => {
              return {
                address: usdcToken.address,
                abi: erc20Abi as Abi,
                metadata: {
                  identifier: Identifier.WALLET,
                  owner: wallet.owner,
                  address: wallet.address,
                  name: wallet.name,
                },
                functionName: func.functionName,
                ...(func.args
                  ? { args: func.args(wallet.owner as Address) }
                  : {}),
              };
            });
          })
          .flat();

        const contracts: ContractType[] = [...vaultsContracts, ...joinnWallets];
        const multicallAddress = MulticallAddresses[chainId] as Address;

        const response = await multicall(config, {
          contracts,
          multicallAddress,
        });

        const formattedResponse = assignResponseToContracts(
          response,
          contracts,
        );

        const newBalances = transformBalanceObj(formattedResponse);

        return { data: newBalances };
      },
    }),
  }),
});

export const { useBalancesQuery, useLazyBalancesQuery } = balanceApi;
