import { Text, Tooltip } from "@/components";
import { ActionButtons, TransferProgress } from "@/components/transaction";
import {
  Button,
  Collapsible,
  CollapsibleContent,
  Separator,
} from "@/components/ui";
import { Transactions, TransactionsStatus } from "@/const";
import { TransactionActions, TransactionState } from "@/hooks";
import { getMaskedAddress, getUnitLabel, sanitizeNumber } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { VaultsFormatted } from "@/services/interfaces";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";

type SummaryProps = {
  onBackPage: (value: boolean) => void;
  state: TransactionState;
  actions: TransactionActions;
  type: "modal" | "panel";
  transaction: Transactions;
};

export function TransactionSummary(props: Readonly<SummaryProps>) {
  const { onBackPage, state, actions, type, transaction } = props;
  const { closeModal, toggleModal } = useModalState();

  const [isProgressShowing, setIsProgressShowing] = useState(false);
  const [amount, setAmount] = useState("0");
  const [units, setUnits] = useState("0");

  function getEstimatedShare() {
    if (transaction === Transactions.DEPOSIT && !state.isToSpendingAccount) {
      return state.amount / (state.to as VaultsFormatted)?.pricePerShare;
    }

    if (transaction === Transactions.WITHDRAW && !state.isFromSpendingAccount) {
      return state.amount * (state.from as VaultsFormatted)?.pricePerShare;
    }

    if (transaction === Transactions.TRANSFER) {
      if (state.isFromSpendingAccount) {
        return state.amount / (state.to as VaultsFormatted)?.pricePerShare;
      } else if (state.isToSpendingAccount) {
        return state.amount * (state.from as VaultsFormatted)?.pricePerShare;
      } else {
        /**
         * Earn account to another earn account
         * Convert the balance (share units) to USDC based on the shareprice of the earn account where the value will be transferred from,
         * then convert the usdc to share units (balance) based on the shareprice of the earn account where the amount will be transferred to.
         */
        const fromData = state.from as VaultsFormatted;
        const fromValue = state.amount * fromData?.pricePerShare; // share units to share value (in usdc)

        const toData = state.to as VaultsFormatted;
        return fromValue / toData?.pricePerShare; // usdc value to share units
      }
    }

    return 0;
  }

  function handleOnTransact() {
    setIsProgressShowing(true);

    switch (transaction) {
      case Transactions.DEPOSIT:
        return actions.onDeposit();
      case Transactions.TRANSFER:
        return actions.onTransfer();
      case Transactions.WITHDRAW:
        return actions.onWithdraw();
      default:
        return null;
    }
  }

  function getLabels() {
    switch (transaction) {
      case Transactions.WITHDRAW:
        return {
          amountLabel: state.isFromSpendingAccount
            ? "USDC"
            : getUnitLabel(amount),
          estimatedLabel: !state.isFromSpendingAccount
            ? "USDC"
            : getUnitLabel(units),
        };

      case Transactions.TRANSFER:
        if (state.isFromSpendingAccount) {
          return {
            amountLabel: "USDC",
            estimatedLabel: getUnitLabel(units),
          };
        } else if (state.isToSpendingAccount) {
          return {
            amountLabel: getUnitLabel(amount),
            estimatedLabel: "USDC",
          };
        } else {
          return {
            amountLabel: getUnitLabel(amount),
            estimatedLabel: getUnitLabel(units),
          };
        }

      case Transactions.DEPOSIT:
      default:
        return {
          amountLabel: "USDC",
          estimatedLabel: state.isToSpendingAccount
            ? "USDC"
            : getUnitLabel(units),
        };
    }
  }

  const { amountLabel, estimatedLabel } = getLabels();

  useEffect(() => {
    setAmount(sanitizeNumber(state.amount.toString()));
    setUnits(sanitizeNumber(getEstimatedShare().toString()));
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-4">
        <Button
          variant="primary"
          disabled={
            state.isProcessing || state.status === TransactionsStatus.SUCCESS
          }
          onClick={() => {
            onBackPage(false);
          }}
          className="rounded-full p-2"
        >
          <ArrowLeft />
        </Button>
        <Text variant="primary" size="lg">
          Transaction Summary
        </Text>
      </div>
      <div>
        <Separator className="mb-2" />
        <div className="flex flex-col gap-2 p-3 capitalize">
          <div className="flex justify-between">
            <Text variant="label">{transaction} From</Text>
            <Tooltip content={state.from?.address}>
              <Text>
                {state.from?.name || getMaskedAddress(state.from?.address)}
              </Text>
            </Tooltip>
          </div>
          <Separator />
          <div className="flex justify-between">
            <Text variant="label">{transaction} To</Text>
            <Tooltip
              content={
                <div>
                  <Text>{state.to?.name}</Text>
                  <Text>{state.to?.address}</Text>
                </div>
              }
            >
              {state.to?.name || getMaskedAddress(state.to?.address)}
            </Tooltip>
          </div>
          <Separator />
          <div className="flex justify-between">
            <Text variant="label">Amount</Text>
            <div className="flex gap-2">
              <Text>{amount}</Text>
              <Text variant="primary">{amountLabel}</Text>
            </div>
          </div>
          <Separator />
          {units && (
            <>
              <div className="flex justify-between">
                <Text variant="label">{`Estimated ${estimatedLabel.includes("Unit") ? "Shares" : "Value"}`}</Text>
                <div className="flex gap-2">
                  <Text>{units}</Text>
                  <Text variant="primary">{estimatedLabel}</Text>
                </div>
              </div>
              <Separator className="mt-2" />
            </>
          )}
        </div>
      </div>
      <Collapsible open={isProgressShowing}>
        <CollapsibleContent>
          <TransferProgress
            status={state.status}
            isProcessing={state.isProcessing}
            transactionDescription={transaction}
          />
        </CollapsibleContent>
      </Collapsible>
      <ActionButtons
        transactionLabel={transaction}
        isProcessing={state.isProcessing}
        isError={!!state.isError}
        status={state.status}
        type={type}
        onClose={() => {
          actions.setAmount(0);
          actions.setStatus(TransactionsStatus.IDLE);
          closeModal();
          if (
            actions.isSpendingAccountAction &&
            !localStorage.getItem("hideSpendingAccountTransactionInfo")
          ) {
            toggleModal({
              id: "spending-account-transaction-info",
              title: "Important",
              isCloseEnabled: true,
            });
          }
        }}
        onTransact={handleOnTransact}
      />
    </div>
  );
}
