import {
  Cell,
  Head,
  Header,
  HistoryDropdownFilter,
  HistoryTile,
  Row,
  ShowMoreButton,
  Table,
} from "@/components/transactionHistory";
import { HistoryRowSkeleton } from "@/components/transactionHistory/HistoryRowSkeleton";
import { MASTERCARD_STATUS } from "@/const";
import { useLazyListCardsQuery } from "@/redux/immersve/immersve.api";

import { useAccounts } from "@/hooks";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { amountFormatter, formatPan, getImmersveTokenData } from "@/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { ConnectToImmersve } from "./Mastercard";

export function CardEnrollment() {
  const { isConnected } = useImmersveState();
  const immersveTokenData = getImmersveTokenData();

  const [fetchCards, { data, isFetching }] = useLazyListCardsQuery();
  const { spendingAccount } = useAccounts();

  const [createdCardStatus, setCreatedCardStatus] = useState<MASTERCARD_STATUS>(
    MASTERCARD_STATUS.ALL,
  );

  const [cards, setCards] = useState<any[]>([]);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (immersveTokenData.cardholderAccountId) {
      fetchCards({ accountId: immersveTokenData.cardholderAccountId });
    }
  }, [fetchCards, immersveTokenData.cardholderAccountId]);

  useEffect(() => {
    if (data) {
      setCards((prevCards) => [...prevCards, ...data.items]);
      setNextCursor(data.pageInfo?.nextCursor || undefined);
      setHasMore(!!data.pageInfo?.nextCursor);
    }
  }, [data]);

  const MastercardHistoryStatusOptions = [
    { id: "1", label: MASTERCARD_STATUS.ALL },
    { id: "2", label: MASTERCARD_STATUS.ACTIVE },
    { id: "3", label: MASTERCARD_STATUS.CANCELED },
    { id: "4", label: MASTERCARD_STATUS.CREATED },
    { id: "5", label: MASTERCARD_STATUS.SHIPPED },
  ];

  const filteredCreatedCards = useMemo(() => {
    if (!cards) return [];
    return cards.filter(
      (transaction) =>
        createdCardStatus === MASTERCARD_STATUS.ALL ||
        transaction.status.toLowerCase() === createdCardStatus.toLowerCase(),
    );
  }, [cards, createdCardStatus]);

  function noHistoryCases() {
    if (isFetching) {
      return null;
    }

    const noResultsMessage =
      createdCardStatus === MASTERCARD_STATUS.ALL
        ? "There is no history yet"
        : "No filtered results";

    return (
      filteredCreatedCards.length === 0 && (
        <div className="flex w-full justify-center text-accent">
          {noResultsMessage}
        </div>
      )
    );
  }

  const fetchMoreCards = () => {
    if (!isFetching && nextCursor) {
      fetchCards({
        accountId: immersveTokenData.cardholderAccountId,
        cursor: nextCursor,
      });
    }
  };

  if (!isConnected) {
    return <ConnectToImmersve />;
  }

  return (
    <>
      <HistoryTile
        balance={amountFormatter(spendingAccount?.balance)}
        title="Mastercard Balance"
      >
        <HistoryDropdownFilter
          title="Card Status"
          label={createdCardStatus}
          options={MastercardHistoryStatusOptions}
          onSelectOption={(option) =>
            setCreatedCardStatus(option?.label as MASTERCARD_STATUS)
          }
        />
      </HistoryTile>

      <Table>
        <Head className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr]">
          <Header>Date Created</Header>
          <Header>Time</Header>
          <Header>Pan No.</Header>
          <Header>Status</Header>
          <Header>Expires At</Header>
        </Head>
        {noHistoryCases()}
        {filteredCreatedCards.map((row) => (
          <Row key={row.id} className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr]">
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.createdAt).format("MM/DD/YYYY")}
            </Cell>
            <Cell caption="Time:" textStyles="text-accent">
              {dayjs(row.createdAt).format("hh:mm A")}
            </Cell>
            <Cell caption="Pan No.:">
              <span className="capitalize">
                {formatPan(row.panFirst6 + "******" + row.panLast4)}
              </span>
            </Cell>
            <Cell caption="Status:" className="capitalize">
              {row.status}
            </Cell>
            <Cell caption="Expires At: ">
              {dayjs(row.expiresAt).format("MM/DD/YYYY")}
            </Cell>
          </Row>
        ))}
      </Table>
      <HistoryRowSkeleton
        isLoading={isFetching}
        className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr]"
        colsSkeletonContentWidths={[
          "w-[80px]",
          "w-[80px]",
          "w-[155px]",
          "w-[100px]",
          "w-[80px]",
        ]}
      />
      {hasMore && (
        <ShowMoreButton isFetching={isFetching} onClick={fetchMoreCards} />
      )}
    </>
  );
}
