import ArrowDownIcon from "@/assets/icons/arrowdown.svg";
import { Text } from "@/components";
import { ExternalWallet } from "@/components/externalWallets";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Card,
  Separator,
} from "@/components/ui";
import { useDashboard, useExternalWallets } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUser } from "@/redux/user/user.slice";
import { amountFormatter } from "@/utils";
import { Plus } from "lucide-react";

export default function ExternalWallets() {
  const { vaultsWalletsBalancesSorted, masterWallet, externalWallets } =
    useExternalWallets();

  const hasExternalWallets = externalWallets.length > 0;
  const { userProfile } = useDashboard();
  const { toggleModal } = useModalState();
  const { lastLoggedInWallet } = useUser();

  function onAddWallet() {
    toggleModal({
      id: "add-wallet",
    });
  }

  return (
    <Accordion type="single" collapsible defaultValue="open">
      <AccordionItem value="open">
        <Card className="flex flex-col gap-5" radius="md">
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap items-center gap-5">
              <AccordionTrigger disabled={!hasExternalWallets} noIcon={true}>
                <Text variant="primary" size="2xl">
                  External Wallets
                </Text>
                {hasExternalWallets && (
                  <img
                    className="relative top-[1px] ml-1 w-3 text-accent transition duration-200 group-data-[state=open]:rotate-180"
                    src={ArrowDownIcon}
                    alt="Arrow Icon"
                  />
                )}
              </AccordionTrigger>
              <Separator orientation="vertical" className="h-8" />
              <Text size="lg" className="text-center">
                {`Total: ${Object.keys(vaultsWalletsBalancesSorted).length}`}
              </Text>
            </div>

            <Button
              variant="primary"
              size="sm"
              onClick={onAddWallet}
              className="text-nowrap"
            >
              <Plus size={16} />
              Add Wallet
            </Button>
          </div>
          {masterWallet && (
            <ExternalWallet
              accountBalance={amountFormatter(
                masterWallet.totalVaultParticipation,
              )}
              chainWalletBalance={masterWallet.usdcBalance}
              isMasterWallet={
                masterWallet.walletAddress.toLowerCase() ===
                userProfile?.address.toLowerCase()
              }
              isWalletConnected={
                lastLoggedInWallet?.toLowerCase() ===
                masterWallet.walletAddress.toLowerCase()
              }
              walletAddress={masterWallet.walletAddress}
              walletName={
                userProfile?.walletName?.name ?? masterWallet.walletName
              }
            />
          )}
          {hasExternalWallets && (
            <AccordionContent className="flex flex-col gap-4">
              {externalWallets.map((wallet) =>
                wallet ? (
                  <ExternalWallet
                    key={wallet.walletAddress}
                    accountBalance={amountFormatter(
                      wallet.totalVaultParticipation,
                    )}
                    chainWalletBalance={wallet.usdcBalance}
                    isMasterWallet={
                      wallet.walletAddress.toLowerCase() ===
                      userProfile?.address.toLowerCase()
                    }
                    isWalletConnected={
                      lastLoggedInWallet?.toLowerCase() ===
                      wallet.walletAddress.toLowerCase()
                    }
                    walletAddress={wallet.walletAddress}
                    walletName={wallet.walletName}
                  />
                ) : null,
              )}
            </AccordionContent>
          )}
        </Card>
      </AccordionItem>
    </Accordion>
  );
}
