import { TransactionsHistoryResponse } from "@/services/interfaces";
import { gql } from "graphql-request";
import { api } from "../baseApi";

export const transactionHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    transactionHistory: builder.query<
      TransactionsHistoryResponse,
      { page: number; limit: number; joinnWalletAddress?: string }
    >({
      query: ({ page, limit, joinnWalletAddress }) => ({
        request: "graphql",
        data: gql`
          query {
            bofWallets(
              where: {
                contractAddress_in: [
                  "${joinnWalletAddress}"
                ]
              }
            ) {
              transactionsCount
              balances {
                id
                amount
                token
              }
              history(first: ${limit}, skip: ${page * limit}, orderBy: blockTimestamp, orderDirection: desc) {
                id
                from
                to
                token
                amount
                type
                transactionHash
                blockTimestamp
                balanceAfter
                fromPricePerShare
                fromShares
                toPricePerShare
                toShares
              }
            }
          }
        `,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 0) {
          return newItems;
        }

        if (currentCache.bofWallets && newItems.bofWallets) {
          currentCache.bofWallets[0].history = [
            ...currentCache.bofWallets[0].history,
            ...newItems.bofWallets[0].history,
          ];

          return currentCache;
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getBalance: builder.query<
      { id: string; amount: string; token: string } | undefined,
      string
    >({
      query: (joinnWalletAddress) => ({
        request: "graphql",
        data: gql`
        query {
          bofWallets(
            where: {
              contractAddress_in: [
                "${joinnWalletAddress}"
              ]
            }
          ) {
            balances {
              id
              amount
              token
            }
          }
        }
      `,
      }),
      transformResponse: (response: {
        bofWallets: {
          balances: { id: string; amount: string; token: string }[];
        }[];
      }) => {
        return response?.bofWallets?.[0]?.balances?.[0];
      },
    }),
  }),
});

export const {
  useTransactionHistoryQuery,
  useLazyTransactionHistoryQuery,
  useGetBalanceQuery,
  useLazyGetBalanceQuery,
} = transactionHistoryApi;
