import { RECENT_TRANSACTIONS_LIMIT, Transactions } from "@/const";
import { useAccounts, useDashboard, useFormatToken } from "@/hooks";
import { useTransactionHistoryQuery } from "@/redux/transactionHistory/transactionHistory.api";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";
import {
  ActivityCard,
  ActivityTile,
  ActivityTileSkeleton,
} from "../activityPanel";
import { Button } from "../ui";

export function RecentActivity() {
  const { adjustSignAndFormat } = useFormatToken();
  const { getContractName } = useAccounts();
  const { userWallets } = useDashboard();
  const navigate = useNavigate();

  const joinnWalletAddress = userWallets?.joinnWalletAddress;
  const { data: historyTransactionData, isFetching } =
    useTransactionHistoryQuery(
      {
        page: 0,
        limit: RECENT_TRANSACTIONS_LIMIT,
        joinnWalletAddress,
      },
      {
        skip: !joinnWalletAddress,
      },
    );
  const historyTransaction = historyTransactionData?.bofWallets?.[0]?.history;

  function handleNavigation(route: string) {
    navigate(route);
  }

  return (
    <ActivityCard title="Recent Activity" route={Routes.HISTORY}>
      {isFetching && (
        <>
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
        </>
      )}

      {!isFetching &&
        historyTransaction &&
        (historyTransaction.length > 0 ? (
          <>
            {historyTransaction
              ?.slice(0, 5)
              .map((transaction) => (
                <ActivityTile
                  key={transaction.transactionHash}
                  date={transaction.blockTimestamp}
                  caption={transaction.type.toLowerCase()}
                  description={
                    transaction.type.toLowerCase() ===
                    Transactions.DEPOSIT.toLowerCase()
                      ? getContractName(transaction.to)
                      : getContractName(transaction.from)
                  }
                  amount={
                    adjustSignAndFormat(transaction.amount, transaction.type) +
                    " USDC"
                  }
                />
              ))}
            <Button
              variant="ghost"
              className="p-0 text-primary"
              size="sm"
              onClick={() => handleNavigation(Routes.HISTORY)}
            >
              Show more
            </Button>
          </>
        ) : (
          <div className="flex h-10 items-center justify-center text-accent">
            No recent history
          </div>
        ))}
    </ActivityCard>
  );
}
