import { Text } from "@/components";
import {
  ConfirmButtons,
  TransferCard,
  TransferDetails,
  TransferDropdown,
  TransferInput,
} from "@/components/transaction";
import { Collapsible, CollapsibleContent } from "@/components/ui";
import { Accounts, EarnAccounts, Transactions } from "@/const";
import { DataArgs, TransactionActions, TransactionState } from "@/hooks";
import {
  formatNumberWithComma,
  getUnitLabel,
  sanitizeNumber,
} from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { useVaultInfoQuery } from "@/redux/vault/vault.api";
import { VaultsFormatted } from "@/services/interfaces";
import { useMemo, useState } from "react";
import { useAccount } from "wagmi";

type TransactionFormProps = {
  state: TransactionState;
  actions: TransactionActions;
  onNextPage: () => void;
  transaction: Transactions;
  hasFromDetails?: boolean;
  hasToDetails?: boolean;
};

type TransactionDetails = VaultsFormatted & {
  availableReserves?: string;
};

export function TransactionForm(props: Readonly<TransactionFormProps>) {
  const {
    state,
    actions,
    onNextPage,
    hasFromDetails,
    hasToDetails,
    transaction,
  } = props;
  const { closeModal } = useModalState();
  const { chainId } = useAccount();

  const { data: fromReserves } = useVaultInfoQuery(
    {
      chainId: chainId!,
      vaultAddress: (state.from as VaultsFormatted)?.contract ?? "0x",
    },
    {
      skip:
        !chainId ||
        transaction === Transactions.DEPOSIT ||
        !state.from ||
        !(state.from as VaultsFormatted)?.contract,
    },
  );

  const { data: toReserves } = useVaultInfoQuery(
    {
      chainId: chainId!,
      vaultAddress: (state.to as VaultsFormatted)?.contract ?? "0x",
    },
    {
      skip:
        !chainId ||
        transaction === Transactions.WITHDRAW ||
        !state.to ||
        !(state.to as VaultsFormatted)?.contract,
    },
  );

  const initialCollapse = useMemo(() => {
    return state.amount ? "amount-details" : "";
  }, [state.amount]);

  const [collapse, setCollapse] = useState<string>(initialCollapse);

  function isEarnAccount(name: string) {
    return Object.values(EarnAccounts).includes(name as EarnAccounts);
  }

  function getAccountDetails(args: DataArgs & { availableReserves?: string }) {
    if (isEarnAccount(args?.name ?? "")) {
      const data = args as TransactionDetails;
      const details = [
        {
          item: "Share Price",
          tooltip:
            "Current Share Price of the assets within this Earn Account.",
          value: `${formatNumberWithComma(data?.pricePerShare)} USDC`,
        },
        {
          item: "Shares Held",
          tooltip:
            "The total number of Shares you currently hold within this Earn Account.",
          value: `${formatNumberWithComma((data?.balance ?? 0) / data?.pricePerShare)} Units`,
        },
        {
          item: "Share Value",
          tooltip: `The total value of the shares you own within this Earn Account. 
          
          Total Share Value is calculated by multiplying the number of Shares you own by the Share Price of the Earn Account.`,
          value: `${formatNumberWithComma(data?.balance ?? 0)} USDC`,
        },
      ];

      if (args?.name !== Accounts.AUTOMATED_LENDING) {
        details.unshift({
          item: "Available USDC Reserves",
          tooltip:
            "The total amount of USDC currently available to instantly withdraw from this Earn Account.",
          value: data.availableReserves
            ? `${formatNumberWithComma(data.availableReserves)} USDC`
            : "0.00 USDC", // dummy while data is not available
        });
      }

      return details;
    } else {
      return [];
    }
  }

  const fromDetails = useMemo(() => {
    return state.from
      ? getAccountDetails({
          ...state.from,
          availableReserves: fromReserves?.reservesValue,
        })
      : [];
  }, [state.from, fromReserves?.reservesValue]);

  const toDetails = useMemo(() => {
    return state.to
      ? getAccountDetails({
          ...state.to,
          availableReserves: toReserves?.reservesValue,
        })
      : [];
  }, [state.to, toReserves?.reservesValue]);

  const amountDetails = useMemo(() => {
    switch (transaction) {
      case Transactions.DEPOSIT: {
        if (state.to?.name !== Accounts.SPENDING_ACCOUNT) {
          const data = state.to as VaultsFormatted;
          const value = state.amount / data?.pricePerShare;

          return [
            {
              item: "Estimated Shares",
              tooltip: `The estimated total number of Shares to be deposited based on the current Share Price of the Earn Account.`,
              value: `${sanitizeNumber(value.toString())} ${getUnitLabel(value)}`,
            },
          ];
        }
        return [];
      }

      case Transactions.TRANSFER: {
        if (state.to?.name === Accounts.SPENDING_ACCOUNT) {
          const data = state.from as VaultsFormatted;

          return [
            {
              item: "Estimated Value",
              tooltip: `The estimated total USDC value to be transferred based on the current Share Price of the Earn Account.`,
              value: `${sanitizeNumber((state.amount * data?.pricePerShare).toString())} USDC`,
            },
          ];
        } else if (state.from?.name === Accounts.SPENDING_ACCOUNT) {
          const data = state.to as VaultsFormatted;
          const value = state.amount / data?.pricePerShare;

          return [
            {
              item: "Estimated Shares",
              tooltip: `The estimated total number of Shares to be transferred based on the current Share Price of the Earn Account.`,
              value: `${sanitizeNumber(value.toString())} ${getUnitLabel(value)}`,
            },
          ];
        } else if (
          state.from?.name !== Accounts.SPENDING_ACCOUNT &&
          state.to?.name !== Accounts.SPENDING_ACCOUNT
        ) {
          /**
           * Earn account to another earn account
           * Convert the balance (share units) to USDC based on the shareprice of the earn account where the value will be transferred from,
           * then convert the usdc to share units (balance) based on the shareprice of the earn account where the amount will be transferred to.
           */
          const fromData = state.from as VaultsFormatted;
          const fromValue = state.amount * fromData?.pricePerShare; // share units to share value (in usdc)

          const toData = state.to as VaultsFormatted;
          const toValue = fromValue / toData?.pricePerShare; // usdc value to share units

          return [
            {
              item: "Estimated Shares",
              tooltip: `Estimated amount of Shares to be received based on the current Share Price of both Earn Accounts.`,
              value: `${sanitizeNumber(toValue.toString())} ${getUnitLabel(toValue)}`,
            },
          ];
        }
        return [];
      }

      case Transactions.WITHDRAW: {
        if (state.from?.name !== Accounts.SPENDING_ACCOUNT) {
          const data = state.from as VaultsFormatted;
          return [
            {
              item: "Estimated Value",
              tooltip:
                "The estimated total USDC value to be withdrawn based on the current Share Price of the Earn Account.",
              value: `${sanitizeNumber((state.amount * data?.pricePerShare).toString())} USDC`,
            },
          ];
        }
        return [];
      }

      default: {
        return [];
      }
    }
  }, [state]);

  function getBalanceLabel(field: "From" | "To") {
    switch (transaction) {
      case Transactions.DEPOSIT:
        return field === "From" || state.isToSpendingAccount ? "USDC" : "Units";
      case Transactions.WITHDRAW:
        return field === "To" || state.isFromSpendingAccount ? "USDC" : "Units";
      case Transactions.TRANSFER:
        return (field === "To" && state.isToSpendingAccount) ||
          (field === "From" && state.isFromSpendingAccount)
          ? "USDC"
          : "Units";
      default:
        return "USDC";
    }
  }

  function getAmountLabel() {
    switch (transaction) {
      case Transactions.WITHDRAW:
      case Transactions.TRANSFER:
        return state.isFromSpendingAccount ? "USDC" : "Shares";
      case Transactions.DEPOSIT:
      default:
        return "USDC";
    }
  }

  function getShareUnits(data: VaultsFormatted) {
    return (data?.balance ?? 0) / data?.pricePerShare;
  }

  // TODO: Refactor this and make it readable and simpler
  function getBalance(field: "From" | "To") {
    switch (transaction) {
      case Transactions.DEPOSIT: {
        if (field === "From") {
          return state.from?.balance;
        }

        if (state.isToSpendingAccount) {
          return state.to?.balance;
        } else {
          return getShareUnits(state.to as VaultsFormatted);
        }
      }

      case Transactions.WITHDRAW: {
        if (field === "To") {
          return state.to?.balance;
        }

        if (state.isFromSpendingAccount) {
          return state.from?.balance;
        } else {
          return getShareUnits(state.from as VaultsFormatted);
        }
      }

      case Transactions.TRANSFER: {
        if (field === "From") {
          if (state.isFromSpendingAccount) {
            return state.from?.balance;
          } else {
            return getShareUnits(state.from as VaultsFormatted);
          }
        } else if (field === "To") {
          if (state.isToSpendingAccount) {
            return state.to?.balance;
          } else {
            return getShareUnits(state.to as VaultsFormatted);
          }
        } else {
          return 0;
        }
      }

      default:
        return 0;
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <TransferDropdown
            data={state.fromRestData}
            label="From:"
            setDataItem={(e) => actions.setFrom(e)}
            selectedDataItem={state.from}
            balanceLabel={getBalanceLabel("From")}
            balance={getBalance("From")}
          />
          {hasFromDetails && <TransferDetails data={fromDetails} />}
        </div>
        <div className="flex flex-col gap-2">
          <TransferDropdown
            data={state.toRestData}
            label="To:"
            setDataItem={(e) => actions.setTo(e)}
            selectedDataItem={state.to}
            balanceLabel={getBalanceLabel("To")}
            balance={getBalance("To")}
          />
          {hasToDetails && <TransferDetails data={toDetails} />}
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <TransferInput
              value={state.amount}
              onChange={(amount) => {
                if (amount === 0) {
                  setCollapse("");
                } else {
                  setCollapse("amount-details");
                }
                return actions.setAmount(amount);
              }}
              maxValue={getBalance("From")}
              amountLabel={getAmountLabel()}
            />
            <Collapsible open={!!state.isError}>
              <CollapsibleContent>
                <Text
                  size="xs"
                  variant="error"
                  className="max-w-sm break-words pt-2"
                >
                  {state.isError}
                </Text>
              </CollapsibleContent>
            </Collapsible>
          </div>
          <TransferCard data={amountDetails} collapsed={collapse} />
        </div>
      </div>
      {actions.isSpendingAccountAction && (
        <Text size="xs" variant="label" className="font-normal">
          Please note that your Spending Account balance can take up to 2
          minutes to update after a Deposit, Transfer, Withdrawal or a
          Mastercard Spend has been made.
        </Text>
      )}
      <ConfirmButtons
        onClose={() => {
          actions.setAmount(0);
          closeModal();
        }}
        onNextPage={onNextPage}
      />
    </div>
  );
}
