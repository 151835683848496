import { cn, formatNumberWithComma } from "@/lib/utils";
import { Text } from "../typography";
import { Card } from "../ui";

type TileProps = {
  title: string;
  value: string | number;
  color: string;
  className?: string;
};

export function Tile({ title, value, color, className }: Readonly<TileProps>) {
  return (
    <Card
      size="sm"
      className={cn(
        "flex items-center gap-3 border-[hsla(var(--secondary)/0.25)] px-5 py-3",
        className,
      )}
    >
      <div
        className="h-3 w-3 rounded-full"
        style={{ backgroundColor: color }}
      />
      <div>
        <Text variant="label" size="sm" className="font-normal">
          {title}
        </Text>
        <Text variant="default" size="md" className="font-normal">
          {formatNumberWithComma(value ?? 0)} USDC
        </Text>
      </div>
    </Card>
  );
}
