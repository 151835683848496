import { useDashboard } from "@/hooks";
import { cn } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { useChangeNameMutation } from "@/redux/user/user.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "../hooks/use-toast";
import { Spinner } from "../spinner";
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../ui";

const NameFormSchema = z.object({
  username: z.string(),
});

type ChangeWalletNameProps = {
  walletAddress: string;
  walletName: string;
};

export function ChangeWalletName({
  walletAddress,
  walletName,
}: Readonly<ChangeWalletNameProps>) {
  const [changeName, { isLoading }] = useChangeNameMutation();
  const { refetch, isUserWalletsLoading } = useDashboard();
  const { closeModal } = useModalState();

  const nameForm = useForm<z.infer<typeof NameFormSchema>>({
    resolver: zodResolver(NameFormSchema),
    defaultValues: {
      username: "",
    },
  });

  async function onSubmitWalletNameChange(
    data: z.infer<typeof NameFormSchema>,
  ) {
    try {
      await changeName({
        address: walletAddress,
        name: data.username,
      });
      refetch();
      closeModal();
    } catch (error) {
      // TODO: Add error handling
      toast({
        description: "Failed to change wallet name",
        variant: "error",
      });
    }
  }

  return (
    <Form {...nameForm}>
      <form
        onSubmit={nameForm.handleSubmit(onSubmitWalletNameChange)}
        className="relative flex flex-col justify-between gap-5"
      >
        <FormField
          control={nameForm.control}
          name="username"
          render={({ field }) => (
            <FormItem className="relative mx-4 sm:mx-0">
              <FormLabel
                className={
                  "absolute top-0 ml-2 h-[10px] bg-background px-2 text-xs"
                }
              >
                Wallet name
              </FormLabel>
              <FormControl>
                <Input placeholder={walletName || "Wallet Name"} {...field} />
              </FormControl>
              <FormDescription>
                This is your wallet public display name.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-5">
          <div className="flex justify-end gap-2">
            <Button
              variant="primary"
              size="sm"
              disabled={isUserWalletsLoading || isLoading}
              className="min-w-20"
            >
              Save
              <Spinner className={cn("mx-0 hidden", isLoading && "block")} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              type="button"
              onClick={closeModal}
              disabled={isUserWalletsLoading || isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
