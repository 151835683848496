import { VAULT_ABI } from "@/abis";
import { formatUnits } from "ethers/lib/utils";
import { useEffect, useMemo } from "react";
import { Abi } from "viem";
import { useReadContracts } from "wagmi";
import { useAccounts } from "./useAccounts";
import { useDashboard } from "./useDashboard";

export function useExternalWallets() {
  const { balances, userProfile } = useDashboard();
  const { wallets } = useAccounts();

  const joinnWalletAddressList = useMemo(
    () =>
      (balances?.wallets || [])
        .map((wallet) => wallet.joinnAddress)
        .filter(Boolean),
    [balances],
  );

  const vaultsAddress = useMemo(
    () => balances?.vaults?.map((vault) => vault.address) ?? [],
    [balances],
  );

  const vaultJoinnWalletPairs = useMemo(
    () =>
      vaultsAddress.flatMap((vaultAddress) =>
        joinnWalletAddressList.map((joinnAddress) => ({
          joinnAddress,
          vaultAddress,
        })),
      ),
    [vaultsAddress, joinnWalletAddressList],
  );

  const vaultsContracts = useMemo(
    () =>
      vaultJoinnWalletPairs.flatMap(({ joinnAddress, vaultAddress }) => {
        if (!joinnAddress || !vaultAddress) return [];

        return [
          {
            address: vaultAddress as `0x${string}`,
            abi: VAULT_ABI as Abi,
            functionName: "balanceOf",
            args: [joinnAddress as `0x${string}`],
          },
          {
            address: vaultAddress as `0x${string}`,
            abi: VAULT_ABI as Abi,
            functionName: "decimals",
            args: [],
          },
          {
            address: vaultAddress as `0x${string}`,
            abi: VAULT_ABI as Abi,
            functionName: "symbol",
            args: [],
          },
        ];
      }),
    [vaultJoinnWalletPairs],
  );

  const {
    data: walletBalancesInVaults,
    isLoading,
    error,
    refetch,
  } = useReadContracts({
    allowFailure: false,
    contracts: vaultsContracts,
  });

  //refetch read contracts when balances are updated
  useEffect(() => {
    if (balances) {
      refetch();
    }
  }, [balances, refetch]);

  const vaultsWalletsBalancesSorted = useMemo(() => {
    if (!walletBalancesInVaults) return {};

    return vaultJoinnWalletPairs.reduce(
      (acc, { joinnAddress, vaultAddress }, index) => {
        if (!joinnAddress) return acc;

        const balanceIndex = index * 3;
        const vaultBalanceRaw =
          (walletBalancesInVaults?.[balanceIndex] as string) || "0";
        const vaultDecimals =
          (walletBalancesInVaults?.[balanceIndex + 1] as number) || 0;
        const vaultSymbol =
          (walletBalancesInVaults?.[balanceIndex + 2] as string) || "";

        const vaultBalance = formatUnits(vaultBalanceRaw, vaultDecimals);

        if (!acc[joinnAddress]) {
          const associatedWallet = balances?.wallets?.find(
            (wallet) => wallet.joinnAddress === joinnAddress,
          );

          acc[joinnAddress] = {
            totalVaultParticipation: 0,
            walletAddress: associatedWallet?.address || "",
            walletName: associatedWallet?.name || "",
            vaults: {},
            usdcDecimals: 6,
            usdcSymbol: "USDC",
            usdcBalance: associatedWallet?.balance?.toString() || "0",
          };
        }

        acc[joinnAddress].vaults[vaultAddress] = {
          balance: vaultBalance,
          decimals: vaultDecimals,
          symbol: vaultSymbol,
        };

        acc[joinnAddress].totalVaultParticipation += parseFloat(vaultBalance);

        return acc;
      },
      {} as Record<
        string,
        {
          totalVaultParticipation: number;
          usdcDecimals: number;
          usdcSymbol: string;
          walletAddress: string;
          walletName: string;
          usdcBalance: string;
          vaults: Record<
            string,
            { balance: string; decimals: number; symbol: string }
          >;
        }
      >,
    );
  }, [walletBalancesInVaults, vaultJoinnWalletPairs, balances, wallets]);

  const masterWallet = useMemo(() => {
    return Object.values(vaultsWalletsBalancesSorted).filter((wallet) => {
      return wallet.walletAddress === userProfile?.address;
    })[0];
  }, [vaultsWalletsBalancesSorted]);

  const externalWallets = useMemo(() => {
    return Object.values(vaultsWalletsBalancesSorted).filter((wallet) => {
      return wallet.walletAddress !== userProfile?.address;
    });
  }, [vaultsWalletsBalancesSorted]);

  return {
    vaultsWalletsBalancesSorted,
    isLoading,
    error,
    masterWallet,
    externalWallets,
  };
}
