import { config } from "@/config";
import {
  SubmitSimulatorAuthorizationReq,
  SubmitSimulatorAuthorizationResp,
} from "@/services/interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =
  config.BLOCKCHAIN_NET === "mainnet"
    ? "https://api.immersve.com/"
    : "https://test.immersve.com/";

export const getNetwork = () => {
  if (config.BLOCKCHAIN_NET === "mainnet") {
    return "polygon-mainnet";
  }
  return "polygon-amoy";
};

const dynamicBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const tokenData = localStorage.getItem("immersveTokenData");
    if (tokenData) {
      const { accessToken } = JSON.parse(tokenData);
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const immersveSimulatorApi = createApi({
  reducerPath: "immersveSimulatorApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    submitSimulatorAuthorization: builder.mutation<
      SubmitSimulatorAuthorizationResp,
      SubmitSimulatorAuthorizationReq
    >({
      query: (body) => ({
        url: `api/simulator/authorize`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSubmitSimulatorAuthorizationMutation } = immersveSimulatorApi;
