import { config as wagmiConfig } from "@/chains/config";
import { toast } from "@/components/hooks/use-toast";
import { config } from "@/config";
import { useCreateChallengeMutation } from "@/redux/immersve/immersve.api";
import {
  useCompleteLoginMutation,
  useInitiateLoginMutation,
  useLogoutMutation,
} from "@/redux/immersve/immersve.authApi";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { AccountAbstraction } from "@/services/AccountAbstraction";
import { getImmersveTokenData, handleError, useEthersSigner } from "@/utils";
import { useEffect, useMemo } from "react";
import { useAccount } from "wagmi";
import { useDashboard } from "./useDashboard";

// TODO: Consolidate types for all generic responses === success | error
type ErrorResponse = {
  data: {
    errorCode: string;
    statusCode: number | string;
    statusName: string;
  };
  status: number | string;
};

type LoginResponse = {
  isError?: boolean;
  isSuccess?: boolean;
  data?: unknown;
  error?: {};
};

export function useImmersve() {
  const [initiateLogin] = useInitiateLoginMutation();
  const [completeLogin] = useCompleteLoginMutation();
  const [logout] = useLogoutMutation();
  const [createChallenge] = useCreateChallengeMutation();
  const { connectImmersve, disconnectImmersve, setConnectingStatus } =
    useImmersveState();
  const { info } = useDashboard();
  const { chainId } = useAccount();

  const signer = useEthersSigner(wagmiConfig, { chainId });

  const isChainImmersveAllowed = useMemo(() => {
    return chainId === (config.BLOCKCHAIN_NET === "mainnet" ? 137 : 80002);
  }, [chainId]);

  // Auto login
  useEffect(() => {
    const autoLogin = async () => {
      const tokenData = localStorage.getItem("immersveTokenData");
      if (tokenData) {
        setConnectingStatus(true);
        try {
          await login();
        } catch (error) {
          console.error("Automatic login failed:", error);
        } finally {
          setConnectingStatus(false);
        }
      }
    };

    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, signer]);

  async function login(): Promise<LoginResponse> {
    // Return response to allow conditional sequential api call if needed
    const loginResponse = {
      isError: false,
      isSuccess: false,
    };

    if (localStorage.getItem("immersveTokenData")) {
      connectImmersve();
      loginResponse.isSuccess = true;
      return loginResponse;
    }

    setConnectingStatus(true);
    try {
      if (!info) throw new Error("No info found");
      const resolvedSigner = await signer;
      const provider = resolvedSigner.provider;
      const AA = await AccountAbstraction.init(info, resolvedSigner, provider);
      await AA.deployContractIfNotDeployed();
      const accountAbstractionAddress = await AA.getAddress();
      const response = await initiateLogin(accountAbstractionAddress).unwrap();
      const signature = await AA.signMessage(response.signingChallenge.message);

      const immersveResponse = await completeLogin({
        loginRequestId: response.id,
        signature,
      });

      if (immersveResponse.data) {
        connectImmersve(JSON.stringify({ ...immersveResponse.data }));
        loginResponse.isSuccess = true;
      }

      // forcing to toast error as the request does not throw 403 as network error
      if (immersveResponse.error) {
        // cast this to ErrorResponse
        const error = (immersveResponse.error as ErrorResponse).data;
        loginResponse.isError = true;
        toast({
          variant: "error",
          title: "Unable to sign you in.",
          description: `${error.statusName}: ${error.errorCode} `,
        });
      }

      setConnectingStatus(false);
      return loginResponse;
    } catch (error) {
      handleError(error);
      disconnectImmersve();
      loginResponse.isError = true;
      return loginResponse;
    } finally {
      setConnectingStatus(false);
    }
  }

  async function immersveLogout() {
    setConnectingStatus(true);
    try {
      await logout({});
      disconnectImmersve();
    } catch (error) {
      handleError(error);
    } finally {
      setConnectingStatus(false);
    }
  }

  async function immersveCreateChallenge(address: string) {
    try {
      const challengeResponse = await createChallenge(address).unwrap();
      return challengeResponse;
    } catch (error) {
      console.error("Error creating challenge:", error);
      throw error;
    }
  }

  return {
    action: {
      createChallenge: immersveCreateChallenge,
      login,
      logout: immersveLogout,
    },
    state: {
      chainId,
      isChainImmersveAllowed,
      tokenData: getImmersveTokenData(),
    },
  };
}
