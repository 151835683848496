import JoinnLogo from "@/assets/images/joinnlogo.svg";
import JoinnWordMark from "@/assets/images/joinnworkmark.svg";
import { Box, BoxContent, Link, Text } from "@/components";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { Connector as ConnectorIcon, useConnectorIcon } from "@/hooks";
import { useLogin } from "@/hooks/useLogin";
import { cn } from "@/lib/utils";
import { useEffect, useRef } from "react";
import { Connector, useAccount, useConnect, useConnectors } from "wagmi";

export function Login() {
  const { grantAccess, signAccess, reAuth } = useLogin();
  const connectors = useConnectors();
  const { connectAsync } = useConnect();
  const { getIcon } = useConnectorIcon();
  const {
    connector: activeConnector,
    isConnected,
    address,
    chainId,
  } = useAccount();

  const clickedConnectorIndexRef = useRef<number | null>(null);

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) return;
    if (!isConnected) return;
    if (!address) return;
    reAuth(address, refreshToken);
  }, [isConnected, address]);

  useEffect(() => {
    // Dynamically load the Zendesk widget script
    const isScriptAdded = document.getElementById("ze-snippet");
    if (!isScriptAdded) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=25dd7787-3b06-4187-ba06-15c4c1d22c56";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  async function handleConnect(connector: Connector, index: number) {
    try {
      clickedConnectorIndexRef.current = index;

      let accountAddress = address;
      let accountChainId = chainId;

      if (!isConnected) {
        const result = await connectAsync({ connector });
        accountAddress = result.accounts[0];
        accountChainId = result.chainId;
      }

      await signAccess({
        address: accountAddress,
        chainId: accountChainId,
      });
    } catch (error) {
      console.error("Error in handleConnect", error);
    } finally {
      clickedConnectorIndexRef.current = null;
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-10 bg-background p-5">
      <div className="relative flex items-center justify-center">
        <img
          src={JoinnLogo}
          alt="Joinn Login Logo"
          className="w-full max-w-screen-md animate-fade-in"
        />
        <img
          src={JoinnWordMark}
          alt="Joinn Wordmark Logo"
          className="absolute w-1/2 max-w-screen-sm animate-fade-in delay-300"
        />
      </div>
      <Text size="md" className="animate-fade-in delay-500">
        Connect with:
      </Text>
      <div className="flex w-full animate-fade-in flex-wrap justify-center gap-5 delay-500">
        {connectors
          .filter((connector) => {
            return (
              connector.type !== "injected" ||
              (connector.type === "injected" && connector.id === "metaMask")
            );
          })
          .map((connector, index) => {
            return (
              <Box
                key={connector.id}
                innerBox="h-full"
                radius="lg"
                className={cn(
                  "h-24 max-w-44 from-secondary/50 to-accent shadow-lg transition-all hover:from-secondary hover:to-primary hover:px-1",
                  clickedConnectorIndexRef.current === index &&
                    (activeConnector?.id === connector.id ||
                      clickedConnectorIndexRef.current === index) &&
                    "animate-bounce from-secondary to-primary px-1",
                )}
              >
                <BoxContent variant="button" className="p-0">
                  <Button
                    className="grid justify-items-center text-nowrap p-5 hover:opacity-100"
                    disabled={!!clickedConnectorIndexRef.current}
                    onClick={() => {
                      handleConnect(connector, index);
                    }}
                  >
                    <img
                      src={getIcon(connector.name as ConnectorIcon)}
                      height={24}
                      width={24}
                      alt={`${connector.name} Icon`}
                    />
                    {connector.name}
                  </Button>
                </BoxContent>
              </Box>
            );
          })}
      </div>
      <Text size="md" className="animate-fade-in text-center delay-700">
        By using Joinn, you agree to our{" "}
        <Tooltip>
          <TooltipTrigger>
            {/* TODO: Make this a Link and remove tooltip when the URL is available */}
            <span className="underline">Terms of Service</span>
          </TooltipTrigger>
          <TooltipContent>
            <Text>Coming soon!</Text>
          </TooltipContent>
        </Tooltip>{" "}
        and our{" "}
        <Link
          to="https://help.joinn.io/hc/en-us/articles/11636020293007-Privacy-Policy/"
          target="_blank"
          className="underline"
        >
          Privacy Policy
        </Link>
        .
      </Text>
    </div>
  );
}
