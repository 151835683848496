"use client";

import { CardContent } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { formatNumberWithComma } from "@/lib/utils";
import { Label, Pie, PieChart } from "recharts";

type ChartProps = {
  chartData: {
    title: string;
    value: number;
    fill: string;
  }[];
};

export function Chart({ chartData }: Readonly<ChartProps>) {
  const chartConfig: ChartConfig = chartData.reduce((acc, item) => {
    acc[item.title] = { color: item.fill };
    return acc;
  }, {} as ChartConfig);

  function getChartValue() {
    return formatNumberWithComma(
      chartData.reduce((acc, data) => acc + data.value, 0),
    );
  }

  return (
    <div className="flex flex-col">
      <CardContent className="flex-1 self-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px] min-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="title"
              innerRadius={80}
              strokeWidth={50}
              paddingAngle={3}
              cornerRadius={15}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-normal"
                        >
                          {getChartValue()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 30}
                          className="fill-muted-foreground text-sm font-normal"
                        >
                          USDC
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 30}
                          className="fill-muted-foreground text-sm font-normal"
                        >
                          Total
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
    </div>
  );
}
