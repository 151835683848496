import { TBONDS_ABI } from "@/abis/tbonds.abi";
import { config } from "@/chains/config";
import { TOKEN_ADDRESSES } from "@/const";
import { formatNumberWithComma } from "@/lib/utils";
import { ErrorContractResponse } from "@/services/interfaces";
import { readContract } from "@wagmi/core";
import { useEffect, useMemo, useState } from "react";
import { Address, formatUnits } from "viem";
import { useAccount } from "wagmi";

export type VaultLimitProps = {
  contractAddr: string;
  skip?: boolean;
};

type LimitProps = {
  data: {
    raw: bigint;
    value: string;
    formatted: string;
  } | null;
  error: ErrorContractResponse | null;
};

export function useVaultLimit(props: VaultLimitProps) {
  const { contractAddr, skip = false } = props;
  const { chainId } = useAccount();

  const initialState = {
    data: null,
    error: null,
  };

  const [remainingLimit, setRemainingLimit] =
    useState<LimitProps>(initialState);
  const [depositLimit, setDepositLimit] = useState<LimitProps>(initialState);

  const tokenAsset = useMemo(() => {
    return chainId
      ? TOKEN_ADDRESSES[chainId].USDC
      : TOKEN_ADDRESSES["80002"].USDC; // default value
  }, [chainId]);

  function formatValue(unit: bigint) {
    const value = formatUnits(unit, tokenAsset.decimals);
    const formatted = formatNumberWithComma(value);

    return formatted;
  }

  async function getDepositLimit() {
    try {
      const depositLimit = (await readContract(config, {
        abi: TBONDS_ABI,
        address: contractAddr.toLowerCase() as Address,
        functionName: "depositLimit",
      })) as bigint;

      setDepositLimit({
        data: {
          raw: depositLimit,
          value: formatUnits(depositLimit, tokenAsset.decimals),
          formatted: `${formatValue(depositLimit)} USDC`,
        },
        error: null,
      });
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e)) as ErrorContractResponse;
      setDepositLimit({
        data: null,
        error,
      });
    }
  }

  async function getRemainingLimit() {
    try {
      const remainingLimit = (await readContract(config, {
        abi: TBONDS_ABI,
        address: contractAddr.toLowerCase() as Address,
        functionName: "availableDepositLimit",
      })) as bigint;

      setRemainingLimit({
        data: {
          raw: remainingLimit,
          value: formatUnits(remainingLimit, tokenAsset.decimals),
          formatted: `${formatValue(remainingLimit)} USDC`,
        },
        error: null,
      });
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e)) as ErrorContractResponse;
      setRemainingLimit({
        data: null,
        error,
      });
    }
  }

  // set deposit limit on initial load
  useEffect(() => {
    if (!skip && contractAddr && contractAddr !== "0x") {
      getRemainingLimit();
      getDepositLimit();
    }
  }, [skip, contractAddr]);

  return {
    depositLimit,
    remainingLimit,
  };
}
