import { TOKEN_ADDRESSES, Transactions } from "@/const";
import { formatUnits, parseUnits } from "viem";
import { useAccount } from "wagmi";

export function useFormatToken() {
  const { chain } = useAccount();

  function parseTokenAmount(amount: string | number) {
    if (!chain) return;
    if (typeof amount === "number") amount = amount.toString();
    return parseUnits(amount, TOKEN_ADDRESSES[chain.id].USDC.decimals);
  }

  function formatTokenAmount(amount?: string, toFixed = 2) {
    if (!amount) return;
    const bigIntAmount = BigInt(amount);
    if (!chain) return;
    return Number(
      formatUnits(bigIntAmount, TOKEN_ADDRESSES[chain.id].USDC.decimals),
    ).toFixed(toFixed);
  }

  function adjustSignAndFormat(amount: string, type?: string) {
    const formattedAmount = formatTokenAmount(amount);
    switch (type?.toLowerCase()) {
      case Transactions.WITHDRAW.toLowerCase():
        return `-${formattedAmount}`;
      case Transactions.DEPOSIT.toLowerCase():
      case Transactions.TRANSFER.toLowerCase():
      default:
        return formattedAmount;
    }
  }

  return {
    parseTokenAmount,
    formatTokenAmount,
    adjustSignAndFormat,
  };
}
