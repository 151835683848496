export type Country = {
  label: string;
  currencyCode: string;
  alpha2Code?: string;
  alpha3Code?: string;
  icon?: string;
  countryCode?: string;
};

export const COUNTRIES = [
  {
    label: "Afghanistan",
    icon: "🇦🇫",
    alpha2Code: "AF",
    alpha3Code: "AFG",
    countryCode: "+93",
    currencyCode: "AFN",
  },
  {
    label: "Åland Islands",
    icon: "🇦🇽",
    alpha2Code: "AX",
    alpha3Code: "ALA",
    countryCode: "+358",
    currencyCode: "EUR",
  },
  {
    label: "Albania",
    icon: "🇦🇱",
    alpha2Code: "AL",
    alpha3Code: "ALB",
    countryCode: "+355",
    currencyCode: "ALL",
  },
  {
    label: "Algeria",
    icon: "🇩🇿",
    alpha2Code: "DZ",
    alpha3Code: "DZA",
    countryCode: "+213",
    currencyCode: "DZD",
  },
  {
    label: "American Samoa",
    icon: "🇦🇸",
    alpha2Code: "AS",
    alpha3Code: "ASM",
    countryCode: "+1684",
    currencyCode: "USD",
  },
  {
    label: "Andorra",
    icon: "🇦🇩",
    alpha2Code: "AD",
    alpha3Code: "AND",
    countryCode: "+376",
    currencyCode: "EUR",
  },
  {
    label: "Angola",
    icon: "🇦🇴",
    alpha2Code: "AO",
    alpha3Code: "AGO",
    countryCode: "+244",
    currencyCode: "AOA",
  },
  {
    label: "Anguilla",
    icon: "🇦🇮",
    alpha2Code: "AI",
    alpha3Code: "AIA",
    countryCode: "+1264",
    currencyCode: "XCD",
  },
  {
    label: "Antarctica",
    icon: "🇦🇶",
    alpha2Code: "AQ",
    alpha3Code: "ATA",
    countryCode: "+672",
    currencyCode: "AUD",
  },
  {
    label: "Antigua and Barbuda",
    icon: "🇦🇬",
    alpha2Code: "AG",
    alpha3Code: "ATG",
    countryCode: "+1268",
    currencyCode: "XCD",
  },
  {
    label: "Argentina",
    icon: "🇦🇷",
    alpha2Code: "AR",
    alpha3Code: "ARG",
    countryCode: "+54",
    currencyCode: "ARS",
  },
  {
    label: "Armenia",
    icon: "🇦🇲",
    alpha2Code: "AM",
    alpha3Code: "ARM",
    countryCode: "+374",
    currencyCode: "AMD",
  },
  {
    label: "Aruba",
    icon: "🇦🇼",
    alpha2Code: "AW",
    alpha3Code: "ABW",
    countryCode: "+297",
    currencyCode: "AWG",
  },
  {
    label: "Australia",
    icon: "🇦🇺",
    alpha2Code: "AU",
    alpha3Code: "AUS",
    countryCode: "+61",
    currencyCode: "AUD",
  },
  {
    label: "Austria",
    icon: "🇦🇹",
    alpha2Code: "AT",
    alpha3Code: "AUT",
    countryCode: "+43",
    currencyCode: "EUR",
  },
  {
    label: "Azerbaijan",
    icon: "🇦🇿",
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    countryCode: "+994",
    currencyCode: "AZN",
  },
  {
    label: "Bahamas",
    icon: "🇧🇸",
    alpha2Code: "BS",
    alpha3Code: "BHS",
    countryCode: "+1242",
    currencyCode: "BSD",
  },
  {
    label: "Bahrain",
    icon: "🇧🇭",
    alpha2Code: "BH",
    alpha3Code: "BHR",
    countryCode: "+973",
    currencyCode: "BHD",
  },
  {
    label: "Bangladesh",
    icon: "🇧🇩",
    alpha2Code: "BD",
    alpha3Code: "BGD",
    countryCode: "+880",
    currencyCode: "BDT",
  },
  {
    label: "Barbados",
    icon: "🇧🇧",
    alpha2Code: "BB",
    alpha3Code: "BRB",
    countryCode: "+1246",
    currencyCode: "BBD",
  },
  {
    label: "Belarus",
    icon: "🇧🇾",
    alpha2Code: "BY",
    alpha3Code: "BLR",
    countryCode: "+375",
    currencyCode: "BYN",
  },
  {
    label: "Belgium",
    icon: "🇧🇪",
    alpha2Code: "BE",
    alpha3Code: "BEL",
    countryCode: "+32",
    currencyCode: "EUR",
  },
  {
    label: "Belize",
    icon: "🇧🇿",
    alpha2Code: "BZ",
    alpha3Code: "BLZ",
    countryCode: "+501",
    currencyCode: "BZD",
  },
  {
    label: "Benin",
    icon: "🇧🇯",
    alpha2Code: "BJ",
    alpha3Code: "BEN",
    countryCode: "+229",
    currencyCode: "XOF",
  },
  {
    label: "Bermuda",
    icon: "🇧🇲",
    alpha2Code: "BM",
    alpha3Code: "BMU",
    countryCode: "+1441",
    currencyCode: "BMD",
  },
  {
    label: "Bhutan",
    icon: "🇧🇹",
    alpha2Code: "BT",
    alpha3Code: "BTN",
    countryCode: "+975",
    currencyCode: "BTN",
  },
  {
    label: "Bolivia",
    icon: "🇧🇴",
    alpha2Code: "BO",
    alpha3Code: "BOL",
    countryCode: "+591",
    currencyCode: "BOB",
  },
  {
    label: "Bosnia and Herzegovina",
    icon: "🇧🇦",
    alpha2Code: "BA",
    alpha3Code: "BIH",
    countryCode: "+387",
    currencyCode: "BAM",
  },
  {
    label: "Botswana",
    icon: "🇧🇼",
    alpha2Code: "BW",
    alpha3Code: "BWA",
    countryCode: "+267",
    currencyCode: "BWP",
  },
  {
    label: "Bouvet Island",
    icon: "🇧🇻",
    alpha2Code: "BV",
    alpha3Code: "BVT",
    countryCode: "+47",
    currencyCode: "NOK",
  },
  {
    label: "Brazil",
    icon: "🇧🇷",
    alpha2Code: "BR",
    alpha3Code: "BRA",
    countryCode: "+55",
    currencyCode: "BRL",
  },
  {
    label: "British Indian Ocean Territory",
    icon: "🇮🇴",
    alpha2Code: "IO",
    alpha3Code: "IOT",
    countryCode: "+246",
    currencyCode: "USD",
  },
  {
    label: "Brunei Darussalam",
    icon: "🇧🇳",
    alpha2Code: "BN",
    alpha3Code: "BRN",
    countryCode: "+673",
    currencyCode: "BND",
  },
  {
    label: "Bulgaria",
    icon: "🇧🇬",
    alpha2Code: "BG",
    alpha3Code: "BGR",
    countryCode: "+359",
    currencyCode: "BGN",
  },
  {
    label: "Burkina Faso",
    icon: "🇧🇫",
    alpha2Code: "BF",
    alpha3Code: "BFA",
    countryCode: "+226",
    currencyCode: "XOF",
  },
  {
    label: "Burundi",
    icon: "🇧🇮",
    alpha2Code: "BI",
    alpha3Code: "BDI",
    countryCode: "+257",
    currencyCode: "BIF",
  },
  {
    label: "Cambodia",
    icon: "🇰🇭",
    alpha2Code: "KH",
    alpha3Code: "KHM",
    countryCode: "+855",
    currencyCode: "KHR",
  },
  {
    label: "Cameroon",
    icon: "🇨🇲",
    alpha2Code: "CM",
    alpha3Code: "CMR",
    countryCode: "+237",
    currencyCode: "XAF",
  },
  {
    label: "Canada",
    icon: "🇨🇦",
    alpha2Code: "CA",
    alpha3Code: "CAN",
    countryCode: "+1",
    currencyCode: "CAD",
  },
  {
    label: "Cape Verde",
    icon: "🇨🇻",
    alpha2Code: "CV",
    alpha3Code: "CPV",
    countryCode: "+238",
    currencyCode: "CVE",
  },
  {
    label: "Cayman Islands",
    icon: "🇰🇾",
    alpha2Code: "KY",
    alpha3Code: "CYM",
    countryCode: "+345",
    currencyCode: "KYD",
  },
  {
    label: "Central African Republic",
    icon: "🇨🇫",
    alpha2Code: "CF",
    alpha3Code: "CAF",
    countryCode: "+236",
    currencyCode: "XAF",
  },
  {
    label: "Chad",
    icon: "🇹🇩",
    alpha2Code: "TD",
    alpha3Code: "TCD",
    countryCode: "+235",
    currencyCode: "XAF",
  },
  {
    label: "Chile",
    icon: "🇨🇱",
    alpha2Code: "CL",
    alpha3Code: "CHL",
    countryCode: "+56",
    currencyCode: "CLP",
  },
  {
    label: "China",
    icon: "🇨🇳",
    alpha2Code: "CN",
    alpha3Code: "CHN",
    countryCode: "+86",
    currencyCode: "CNY",
  },
  {
    label: "Christmas Island",
    icon: "🇨🇽",
    alpha2Code: "CX",
    alpha3Code: "CXR",
    countryCode: "+61",
    currencyCode: "AUD",
  },
  {
    label: "Cocos (Keeling) Islands",
    icon: "🇨🇨",
    alpha2Code: "CC",
    alpha3Code: "CCK",
    countryCode: "+61",
    currencyCode: "AUD",
  },
  {
    label: "Colombia",
    icon: "🇨🇴",
    alpha2Code: "CO",
    alpha3Code: "COL",
    countryCode: "+57",
    currencyCode: "COP",
  },
  {
    label: "Comoros",
    icon: "🇰🇲",
    alpha2Code: "KM",
    alpha3Code: "COM",
    countryCode: "+269",
    currencyCode: "KMF",
  },
  {
    label: "Congo",
    icon: "🇨🇬",
    alpha2Code: "CG",
    alpha3Code: "COG",
    countryCode: "+242",
    currencyCode: "XAF",
  },
  {
    label: "Congo, The Democratic Republic of the Congo",
    icon: "🇨🇩",
    alpha2Code: "CD",
    alpha3Code: "COD",
    countryCode: "+243",
    currencyCode: "CDF",
  },
  {
    label: "Cook Islands",
    icon: "🇨🇰",
    alpha2Code: "CK",
    alpha3Code: "COK",
    countryCode: "+682",
    currencyCode: "NZD",
  },
  {
    label: "Costa Rica",
    icon: "🇨🇷",
    alpha2Code: "CR",
    alpha3Code: "CRI",
    countryCode: "+506",
    currencyCode: "CRC",
  },
  {
    label: "Côte d'Ivoire",
    icon: "🇨🇮",
    alpha2Code: "CI",
    alpha3Code: "CIV",
    countryCode: "+225",
    currencyCode: "XOF",
  },
  {
    label: "Croatia",
    icon: "🇭🇷",
    alpha2Code: "HR",
    alpha3Code: "HRV",
    countryCode: "+385",
    currencyCode: "HRK",
  },
  {
    label: "Cuba",
    icon: "🇨🇺",
    alpha2Code: "CU",
    alpha3Code: "CUB",
    countryCode: "+53",
    currencyCode: "CUP",
  },
  {
    label: "Cyprus",
    icon: "🇨🇾",
    alpha2Code: "CY",
    alpha3Code: "CYP",
    countryCode: "+357",
    currencyCode: "EUR",
  },
  {
    label: "Czech Republic",
    icon: "🇨🇿",
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    countryCode: "+420",
    currencyCode: "CZK",
  },
  {
    label: "Denmark",
    icon: "🇩🇰",
    alpha2Code: "DK",
    alpha3Code: "DNK",
    countryCode: "+45",
    currencyCode: "DKK",
  },
  {
    label: "Djibouti",
    icon: "🇩🇯",
    alpha2Code: "DJ",
    alpha3Code: "DJI",
    countryCode: "+253",
    currencyCode: "DJF",
  },
  {
    label: "Dominica",
    icon: "🇩🇲",
    alpha2Code: "DM",
    alpha3Code: "DMA",
    countryCode: "+1767",
    currencyCode: "XCD",
  },
  {
    label: "Dominican Republic",
    icon: "🇩🇴",
    alpha2Code: "DO",
    alpha3Code: "DOM",
    countryCode: "+1849",
    currencyCode: "DOP",
  },
  {
    label: "Ecuador",
    icon: "🇪🇨",
    alpha2Code: "EC",
    alpha3Code: "ECU",
    countryCode: "+593",
    currencyCode: "USD",
  },
  {
    label: "Egypt",
    icon: "🇪🇬",
    alpha2Code: "EG",
    alpha3Code: "EGY",
    countryCode: "+20",
    currencyCode: "EGP",
  },
  {
    label: "El Salvador",
    icon: "🇸🇻",
    alpha2Code: "SV",
    alpha3Code: "SLV",
    countryCode: "+503",
    currencyCode: "USD",
  },
  {
    label: "Equatorial Guinea",
    icon: "🇬🇶",
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    countryCode: "+240",
    currencyCode: "XAF",
  },
  {
    label: "Eritrea",
    icon: "🇪🇷",
    alpha2Code: "ER",
    alpha3Code: "ERI",
    countryCode: "+291",
    currencyCode: "ERN",
  },
  {
    label: "Estonia",
    icon: "🇪🇪",
    alpha2Code: "EE",
    alpha3Code: "EST",
    countryCode: "+372",
    currencyCode: "EUR",
  },
  {
    label: "Ethiopia",
    icon: "🇪🇹",
    alpha2Code: "ET",
    alpha3Code: "ETH",
    countryCode: "+251",
    currencyCode: "ETB",
  },
  {
    label: "Falkland Islands (Malvinas)",
    icon: "🇫🇰",
    alpha2Code: "FK",
    alpha3Code: "FLK",
    countryCode: "+500",
    currencyCode: "FKP",
  },
  {
    label: "Faroe Islands",
    icon: "🇫🇴",
    alpha2Code: "FO",
    alpha3Code: "FRO",
    countryCode: "+298",
    currencyCode: "DKK",
  },
  {
    label: "Fiji",
    icon: "🇫🇯",
    alpha2Code: "FJ",
    alpha3Code: "FJI",
    countryCode: "+679",
    currencyCode: "FJD",
  },
  {
    label: "Finland",
    icon: "🇫🇮",
    alpha2Code: "FI",
    alpha3Code: "FIN",
    countryCode: "+358",
    currencyCode: "EUR",
  },
  {
    label: "France",
    icon: "🇫🇷",
    alpha2Code: "FR",
    alpha3Code: "FRA",
    countryCode: "+33",
    currencyCode: "EUR",
  },
  {
    label: "French Guiana",
    icon: "🇬🇫",
    alpha2Code: "GF",
    alpha3Code: "GUF",
    countryCode: "+594",
    currencyCode: "EUR",
  },
  {
    label: "French Polynesia",
    icon: "🇵🇫",
    alpha2Code: "PF",
    alpha3Code: "PYF",
    countryCode: "+689",
    currencyCode: "XPF",
  },
  {
    label: "French Southern Territories",
    icon: "🇹🇫",
    alpha2Code: "TF",
    alpha3Code: "ATF",
    countryCode: "+262",
    currencyCode: "EUR",
  },
  {
    label: "Gabon",
    icon: "🇬🇦",
    alpha2Code: "GA",
    alpha3Code: "GAB",
    countryCode: "+241",
    currencyCode: "XAF",
  },
  {
    label: "Gambia",
    icon: "🇬🇲",
    alpha2Code: "GM",
    alpha3Code: "GMB",
    countryCode: "+220",
    currencyCode: "GMD",
  },
  {
    label: "Georgia",
    icon: "🇬🇪",
    alpha2Code: "GE",
    alpha3Code: "GEO",
    countryCode: "+995",
    currencyCode: "GEL",
  },
  {
    label: "Germany",
    icon: "🇩🇪",
    alpha2Code: "DE",
    alpha3Code: "DEU",
    countryCode: "+49",
    currencyCode: "EUR",
  },
  {
    label: "Ghana",
    icon: "🇬🇭",
    alpha2Code: "GH",
    alpha3Code: "GHA",
    countryCode: "+233",
    currencyCode: "GHS",
  },
  {
    label: "Gibraltar",
    icon: "🇬🇮",
    alpha2Code: "GI",
    alpha3Code: "GIB",
    countryCode: "+350",
    currencyCode: "GIP",
  },
  {
    label: "Greece",
    icon: "🇬🇷",
    alpha2Code: "GR",
    alpha3Code: "GRC",
    countryCode: "+30",
    currencyCode: "EUR",
  },
  {
    label: "Greenland",
    icon: "🇬🇱",
    alpha2Code: "GL",
    alpha3Code: "GRL",
    countryCode: "+299",
    currencyCode: "DKK",
  },
  {
    label: "Grenada",
    icon: "🇬🇩",
    alpha2Code: "GD",
    alpha3Code: "GRD",
    countryCode: "+1473",
    currencyCode: "XCD",
  },
  {
    label: "Guadeloupe",
    icon: "🇬🇵",
    alpha2Code: "GP",
    alpha3Code: "GLP",
    countryCode: "+590",
    currencyCode: "EUR",
  },
  {
    label: "Guam",
    icon: "🇬🇺",
    alpha2Code: "GU",
    alpha3Code: "GUM",
    countryCode: "+1671",
    currencyCode: "USD",
  },
  {
    label: "Guatemala",
    icon: "🇬🇹",
    alpha2Code: "GT",
    alpha3Code: "GTM",
    countryCode: "+502",
    currencyCode: "GTQ",
  },
  {
    label: "Guernsey",
    icon: "🇬🇬",
    alpha2Code: "GG",
    alpha3Code: "GGY",
    countryCode: "+44",
    currencyCode: "GBP",
  },
  {
    label: "Guinea",
    icon: "🇬🇳",
    alpha2Code: "GN",
    alpha3Code: "GIN",
    countryCode: "+224",
    currencyCode: "GNF",
  },
  {
    label: "Guinea-Bissau",
    icon: "🇬🇼",
    alpha2Code: "GW",
    alpha3Code: "GNB",
    countryCode: "+245",
    currencyCode: "XOF",
  },
  {
    label: "Guyana",
    icon: "🇬🇾",
    alpha2Code: "GY",
    alpha3Code: "GUY",
    countryCode: "+592",
    currencyCode: "GYD",
  },
  {
    label: "Haiti",
    icon: "🇭🇹",
    alpha2Code: "HT",
    alpha3Code: "HTI",
    countryCode: "+509",
    currencyCode: "HTG",
  },
  {
    label: "Heard Island and Mcdonald Islands",
    icon: "🇭🇲",
    alpha2Code: "HM",
    alpha3Code: "HMD",
    countryCode: "+672",
    currencyCode: "AUD",
  },
  {
    label: "Holy See (Vatican City State)",
    icon: "🇻🇦",
    alpha2Code: "VA",
    alpha3Code: "VAT",
    countryCode: "+379",
    currencyCode: "EUR",
  },
  {
    label: "Honduras",
    icon: "🇭🇳",
    alpha2Code: "HN",
    alpha3Code: "HND",
    countryCode: "+504",
    currencyCode: "HNL",
  },
  {
    label: "Hong Kong",
    icon: "🇭🇰",
    alpha2Code: "HK",
    alpha3Code: "HKG",
    countryCode: "+852",
    currencyCode: "HKD",
  },
  {
    label: "Hungary",
    icon: "🇭🇺",
    alpha2Code: "HU",
    alpha3Code: "HUN",
    countryCode: "+36",
    currencyCode: "HUF",
  },
  {
    label: "Iceland",
    icon: "🇮🇸",
    alpha2Code: "IS",
    alpha3Code: "ISL",
    countryCode: "+354",
    currencyCode: "ISK",
  },
  {
    label: "India",
    icon: "🇮🇳",
    alpha2Code: "IN",
    alpha3Code: "IND",
    countryCode: "+91",
    currencyCode: "INR",
  },
  {
    label: "Indonesia",
    icon: "🇮🇩",
    alpha2Code: "ID",
    alpha3Code: "IDN",
    countryCode: "+62",
    currencyCode: "IDR",
  },
  {
    label: "Iran",
    icon: "🇮🇷",
    alpha2Code: "IR",
    alpha3Code: "IRN",
    countryCode: "+98",
    currencyCode: "IRR",
  },
  {
    label: "Iraq",
    icon: "🇮🇶",
    alpha2Code: "IQ",
    alpha3Code: "IRQ",
    countryCode: "+964",
    currencyCode: "IQD",
  },
  {
    label: "Ireland",
    icon: "🇮🇪",
    alpha2Code: "IE",
    alpha3Code: "IRL",
    countryCode: "+353",
    currencyCode: "EUR",
  },
  {
    label: "Isle of Man",
    icon: "🇮🇲",
    alpha2Code: "IM",
    alpha3Code: "IMN",
    countryCode: "+44",
    currencyCode: "GBP",
  },
  {
    label: "Israel",
    icon: "🇮🇱",
    alpha2Code: "IL",
    alpha3Code: "ISR",
    countryCode: "+972",
    currencyCode: "ILS",
  },
  {
    label: "Italy",
    icon: "🇮🇹",
    alpha2Code: "IT",
    alpha3Code: "ITA",
    countryCode: "+39",
    currencyCode: "EUR",
  },
  {
    label: "Jamaica",
    icon: "🇯🇲",
    alpha2Code: "JM",
    alpha3Code: "JAM",
    countryCode: "+1876",
    currencyCode: "JMD",
  },
  {
    label: "Japan",
    icon: "🇯🇵",
    alpha2Code: "JP",
    alpha3Code: "JPN",
    countryCode: "+81",
    currencyCode: "JPY",
  },
  {
    label: "Jersey",
    icon: "🇯🇪",
    alpha2Code: "JE",
    alpha3Code: "JEY",
    countryCode: "+44",
    currencyCode: "GBP",
  },
  {
    label: "Jordan",
    icon: "🇯🇴",
    alpha2Code: "JO",
    alpha3Code: "JOR",
    countryCode: "+962",
    currencyCode: "JOD",
  },
  {
    label: "Kazakhstan",
    icon: "🇰🇿",
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    countryCode: "+7",
    currencyCode: "KZT",
  },
  {
    label: "Kenya",
    icon: "🇰🇪",
    alpha2Code: "KE",
    alpha3Code: "KEN",
    countryCode: "+254",
    currencyCode: "KES",
  },
  {
    label: "Kiribati",
    icon: "🇰🇮",
    alpha2Code: "KI",
    alpha3Code: "KIR",
    countryCode: "+686",
    currencyCode: "AUD",
  },
  {
    label: "Korea, Democratic People's Republic of Korea",
    icon: "🇰🇵",
    alpha2Code: "KP",
    alpha3Code: "PRK",
    countryCode: "+850",
    currencyCode: "KPW",
  },
  {
    label: "Korea, Republic of South Korea",
    icon: "🇰🇷",
    alpha2Code: "KR",
    alpha3Code: "KOR",
    countryCode: "+82",
    currencyCode: "KRW",
  },
  {
    label: "Kosovo",
    icon: "🇽🇰",
    alpha2Code: "XK",
    alpha3Code: "XKX",
    countryCode: "+383",
    currencyCode: "EUR",
  },
  {
    label: "Kuwait",
    icon: "🇰🇼",
    alpha2Code: "KW",
    alpha3Code: "KWT",
    countryCode: "+965",
    currencyCode: "KWD",
  },
  {
    label: "Kyrgyzstan",
    icon: "🇰🇬",
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    countryCode: "+996",
    currencyCode: "KGS",
  },
  {
    label: "Laos",
    icon: "🇱🇦",
    alpha2Code: "LA",
    alpha3Code: "LAO",
    countryCode: "+856",
    currencyCode: "LAK",
  },
  {
    label: "Latvia",
    icon: "🇱🇻",
    alpha2Code: "LV",
    alpha3Code: "LVA",
    countryCode: "+371",
    currencyCode: "EUR",
  },
  {
    label: "Lebanon",
    icon: "🇱🇧",
    alpha2Code: "LB",
    alpha3Code: "LBN",
    countryCode: "+961",
    currencyCode: "LBP",
  },
  {
    label: "Lesotho",
    icon: "🇱🇸",
    alpha2Code: "LS",
    alpha3Code: "LSO",
    countryCode: "+266",
    currencyCode: "LSL",
  },
  {
    label: "Liberia",
    icon: "🇱🇷",
    alpha2Code: "LR",
    alpha3Code: "LBR",
    countryCode: "+231",
    currencyCode: "LRD",
  },
  {
    label: "Libyan Arab Jamahiriya",
    icon: "🇱🇾",
    alpha2Code: "LY",
    alpha3Code: "LBY",
    countryCode: "+218",
    currencyCode: "LYD",
  },
  {
    label: "Liechtenstein",
    icon: "🇱🇮",
    alpha2Code: "LI",
    alpha3Code: "LIE",
    countryCode: "+423",
    currencyCode: "CHF",
  },
  {
    label: "Lithuania",
    icon: "🇱🇹",
    alpha2Code: "LT",
    alpha3Code: "LTU",
    countryCode: "+370",
    currencyCode: "EUR",
  },
  {
    label: "Luxembourg",
    icon: "🇱🇺",
    alpha2Code: "LU",
    alpha3Code: "LUX",
    countryCode: "+352",
    currencyCode: "EUR",
  },
  {
    label: "Macao",
    icon: "🇲🇴",
    alpha2Code: "MO",
    alpha3Code: "MAC",
    countryCode: "+853",
    currencyCode: "MOP",
  },
  {
    label: "Macedonia",
    icon: "🇲🇰",
    alpha2Code: "MK",
    alpha3Code: "MKD",
    countryCode: "+389",
    currencyCode: "MKD",
  },
  {
    label: "Madagascar",
    icon: "🇲🇬",
    alpha2Code: "MG",
    alpha3Code: "MDG",
    countryCode: "+261",
    currencyCode: "MGA",
  },
  {
    label: "Malawi",
    icon: "🇲🇼",
    alpha2Code: "MW",
    alpha3Code: "MWI",
    countryCode: "+265",
    currencyCode: "MWK",
  },
  {
    label: "Malaysia",
    icon: "🇲🇾",
    alpha2Code: "MY",
    alpha3Code: "MYS",
    countryCode: "+60",
    currencyCode: "MYR",
  },
  {
    label: "Maldives",
    icon: "🇲🇻",
    alpha2Code: "MV",
    alpha3Code: "MDV",
    countryCode: "+960",
    currencyCode: "MVR",
  },
  {
    label: "Mali",
    icon: "🇲🇱",
    alpha2Code: "ML",
    alpha3Code: "MLI",
    countryCode: "+223",
    currencyCode: "XOF",
  },
  {
    label: "Malta",
    icon: "🇲🇹",
    alpha2Code: "MT",
    alpha3Code: "MLT",
    countryCode: "+356",
    currencyCode: "EUR",
  },
  {
    label: "Marshall Islands",
    icon: "🇲🇭",
    alpha2Code: "MH",
    alpha3Code: "MHL",
    countryCode: "+692",
    currencyCode: "USD",
  },
  {
    label: "Martinique",
    icon: "🇲🇶",
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    countryCode: "+596",
    currencyCode: "EUR",
  },
  {
    label: "Mauritania",
    icon: "🇲🇷",
    alpha2Code: "MR",
    alpha3Code: "MRT",
    countryCode: "+222",
    currencyCode: "MRO",
  },
  {
    label: "Mauritius",
    icon: "🇲🇺",
    alpha2Code: "MU",
    alpha3Code: "MUS",
    countryCode: "+230",
    currencyCode: "MUR",
  },
  {
    label: "Mayotte",
    icon: "🇾🇹",
    alpha2Code: "YT",
    alpha3Code: "MYT",
    countryCode: "+262",
    currencyCode: "EUR",
  },
  {
    label: "Mexico",
    icon: "🇲🇽",
    alpha2Code: "MX",
    alpha3Code: "MEX",
    countryCode: "+52",
    currencyCode: "MXN",
  },
  {
    label: "Micronesia, Federated States of Micronesia",
    icon: "🇫🇲",
    alpha2Code: "FM",
    alpha3Code: "FSM",
    countryCode: "+691",
    currencyCode: "USD",
  },
  {
    label: "Moldova",
    icon: "🇲🇩",
    alpha2Code: "MD",
    alpha3Code: "MDA",
    countryCode: "+373",
    currencyCode: "MDL",
  },
  {
    label: "Monaco",
    icon: "🇲🇨",
    alpha2Code: "MC",
    alpha3Code: "MCO",
    countryCode: "+377",
    currencyCode: "EUR",
  },
  {
    label: "Mongolia",
    icon: "🇲🇳",
    alpha2Code: "MN",
    alpha3Code: "MNG",
    countryCode: "+976",
    currencyCode: "MNT",
  },
  {
    label: "Montenegro",
    icon: "🇲🇪",
    alpha2Code: "ME",
    alpha3Code: "MNE",
    countryCode: "+382",
    currencyCode: "EUR",
  },
  {
    label: "Montserrat",
    icon: "🇲🇸",
    alpha2Code: "MS",
    alpha3Code: "MSR",
    countryCode: "+1664",
    currencyCode: "XCD",
  },
  {
    label: "Morocco",
    icon: "🇲🇦",
    alpha2Code: "MA",
    alpha3Code: "MAR",
    countryCode: "+212",
    currencyCode: "MAD",
  },
  {
    label: "Mozambique",
    icon: "🇲🇿",
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    countryCode: "+258",
    currencyCode: "MZN",
  },
  {
    label: "Myanmar",
    icon: "🇲🇲",
    alpha2Code: "MM",
    alpha3Code: "MMR",
    countryCode: "+95",
    currencyCode: "MMK",
  },
  {
    label: "Namibia",
    icon: "🇳🇦",
    alpha2Code: "NA",
    alpha3Code: "NAM",
    countryCode: "+264",
    currencyCode: "NAD",
  },
  {
    label: "Nauru",
    icon: "🇳🇷",
    alpha2Code: "NR",
    alpha3Code: "NRU",
    countryCode: "+674",
    currencyCode: "AUD",
  },
  {
    label: "Nepal",
    icon: "🇳🇵",
    alpha2Code: "NP",
    alpha3Code: "NPL",
    countryCode: "+977",
    currencyCode: "NPR",
  },
  {
    label: "Netherlands",
    icon: "🇳🇱",
    alpha2Code: "NL",
    alpha3Code: "NLD",
    countryCode: "+31",
    currencyCode: "EUR",
  },
  {
    label: "Netherlands Antilles",
    icon: "",
    alpha2Code: "AN",
    alpha3Code: "ANT",
    countryCode: "+599",
    currencyCode: "ANG",
  },
  {
    label: "New Caledonia",
    icon: "🇳🇨",
    alpha2Code: "NC",
    alpha3Code: "NCL",
    countryCode: "+687",
    currencyCode: "XPF",
  },
  {
    label: "New Zealand",
    icon: "🇳🇿",
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    countryCode: "+64",
    currencyCode: "NZD",
  },
  {
    label: "Nicaragua",
    icon: "🇳🇮",
    alpha2Code: "NI",
    alpha3Code: "NIC",
    countryCode: "+505",
    currencyCode: "NIO",
  },
  {
    label: "Niger",
    icon: "🇳🇪",
    alpha2Code: "NE",
    alpha3Code: "NER",
    countryCode: "+227",
    currencyCode: "XOF",
  },
  {
    label: "Nigeria",
    icon: "🇳🇬",
    alpha2Code: "NG",
    alpha3Code: "NGA",
    countryCode: "+234",
    currencyCode: "NGN",
  },
  {
    label: "Niue",
    icon: "🇳🇺",
    alpha2Code: "NU",
    alpha3Code: "NIU",
    countryCode: "+683",
    currencyCode: "NZD",
  },
  {
    label: "Norfolk Island",
    icon: "🇳🇫",
    alpha2Code: "NF",
    alpha3Code: "NFK",
    countryCode: "+672",
    currencyCode: "AUD",
  },
  {
    label: "Northern Mariana Islands",
    icon: "🇲🇵",
    alpha2Code: "MP",
    alpha3Code: "MNP",
    countryCode: "+1670",
    currencyCode: "USD",
  },
  {
    label: "Norway",
    icon: "🇳🇴",
    alpha2Code: "NO",
    alpha3Code: "NOR",
    countryCode: "+47",
    currencyCode: "NOK",
  },
  {
    label: "Oman",
    icon: "🇴🇲",
    alpha2Code: "OM",
    alpha3Code: "OMN",
    countryCode: "+968",
    currencyCode: "OMR",
  },
  {
    label: "Pakistan",
    icon: "🇵🇰",
    alpha2Code: "PK",
    alpha3Code: "PAK",
    countryCode: "+92",
    currencyCode: "PKR",
  },
  {
    label: "Palau",
    icon: "🇵🇼",
    alpha2Code: "PW",
    alpha3Code: "PLW",
    countryCode: "+680",
    currencyCode: "USD",
  },
  {
    label: "Palestinian Territory, Occupied",
    icon: "🇵🇸",
    alpha2Code: "PS",
    alpha3Code: "PSE",
    countryCode: "+970",
    currencyCode: "ILS",
  },
  {
    label: "Panama",
    icon: "🇵🇦",
    alpha2Code: "PA",
    alpha3Code: "PAN",
    countryCode: "+507",
    currencyCode: "PAB",
  },
  {
    label: "Papua New Guinea",
    icon: "🇵🇬",
    alpha2Code: "PG",
    alpha3Code: "PNG",
    countryCode: "+675",
    currencyCode: "PGK",
  },
  {
    label: "Paraguay",
    icon: "🇵🇾",
    alpha2Code: "PY",
    alpha3Code: "PRY",
    countryCode: "+595",
    currencyCode: "PYG",
  },
  {
    label: "Peru",
    icon: "🇵🇪",
    alpha2Code: "PE",
    alpha3Code: "PER",
    countryCode: "+51",
    currencyCode: "PEN",
  },
  {
    label: "Philippines",
    icon: "🇵🇭",
    alpha2Code: "PH",
    alpha3Code: "PHL",
    countryCode: "+63",
    currencyCode: "PHP",
  },
  {
    label: "Pitcairn",
    icon: "🇵🇳",
    alpha2Code: "PN",
    alpha3Code: "PCN",
    countryCode: "+64",
    currencyCode: "NZD",
  },
  {
    label: "Poland",
    icon: "🇵🇱",
    alpha2Code: "PL",
    alpha3Code: "POL",
    countryCode: "+48",
    currencyCode: "PLN",
  },
  {
    label: "Portugal",
    icon: "🇵🇹",
    alpha2Code: "PT",
    alpha3Code: "PRT",
    countryCode: "+351",
    currencyCode: "EUR",
  },
  {
    label: "Puerto Rico",
    icon: "🇵🇷",
    alpha2Code: "PR",
    alpha3Code: "PRI",
    countryCode: "+1939",
    currencyCode: "USD",
  },
  {
    label: "Qatar",
    icon: "🇶🇦",
    alpha2Code: "QA",
    alpha3Code: "QAT",
    countryCode: "+974",
    currencyCode: "QAR",
  },
  {
    label: "Romania",
    icon: "🇷🇴",
    alpha2Code: "RO",
    alpha3Code: "ROU",
    countryCode: "+40",
    currencyCode: "RON",
  },
  {
    label: "Russia",
    icon: "🇷🇺",
    alpha2Code: "RU",
    alpha3Code: "RUS",
    countryCode: "+7",
    currencyCode: "RUB",
  },
  {
    label: "Rwanda",
    icon: "🇷🇼",
    alpha2Code: "RW",
    alpha3Code: "RWA",
    countryCode: "+250",
    currencyCode: "RWF",
  },
  {
    label: "Reunion",
    icon: "🇷🇪",
    alpha2Code: "RE",
    alpha3Code: "REU",
    countryCode: "+262",
    currencyCode: "EUR",
  },
  {
    label: "Saint Barthelemy",
    icon: "🇧🇱",
    alpha2Code: "BL",
    alpha3Code: "BLM",
    countryCode: "+590",
    currencyCode: "EUR",
  },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha",
    icon: "🇸🇭",
    alpha2Code: "SH",
    alpha3Code: "SHN",
    countryCode: "+290",
    currencyCode: "SHP",
  },
  {
    label: "Saint Kitts and Nevis",
    icon: "🇰🇳",
    alpha2Code: "KN",
    alpha3Code: "KNA",
    countryCode: "+1869",
    currencyCode: "XCD",
  },
  {
    label: "Saint Lucia",
    icon: "🇱🇨",
    alpha2Code: "LC",
    alpha3Code: "LCA",
    countryCode: "+1758",
    currencyCode: "XCD",
  },
  {
    label: "Saint Martin",
    icon: "🇲🇫",
    alpha2Code: "MF",
    alpha3Code: "MAF",
    countryCode: "+590",
    currencyCode: "EUR",
  },
  {
    label: "Saint Pierre and Miquelon",
    icon: "🇵🇲",
    alpha2Code: "PM",
    alpha3Code: "SPM",
    countryCode: "+508",
    currencyCode: "EUR",
  },
  {
    label: "Saint Vincent and the Grenadines",
    icon: "🇻🇨",
    alpha2Code: "VC",
    alpha3Code: "VCT",
    countryCode: "+1784",
    currencyCode: "XCD",
  },
  {
    label: "Samoa",
    icon: "🇼🇸",
    alpha2Code: "WS",
    alpha3Code: "WSM",
    countryCode: "+685",
    currencyCode: "WST",
  },
  {
    label: "San Marino",
    icon: "🇸🇲",
    alpha2Code: "SM",
    alpha3Code: "SMR",
    countryCode: "+378",
    currencyCode: "EUR",
  },
  {
    label: "Sao Tome and Principe",
    icon: "🇸🇹",
    alpha2Code: "ST",
    alpha3Code: "STP",
    countryCode: "+239",
    currencyCode: "STD",
  },
  {
    label: "Saudi Arabia",
    icon: "🇸🇦",
    alpha2Code: "SA",
    alpha3Code: "SAU",
    countryCode: "+966",
    currencyCode: "SAR",
  },
  {
    label: "Senegal",
    icon: "🇸🇳",
    alpha2Code: "SN",
    alpha3Code: "SEN",
    countryCode: "+221",
    currencyCode: "XOF",
  },
  {
    label: "Serbia",
    icon: "🇷🇸",
    alpha2Code: "RS",
    alpha3Code: "SRB",
    countryCode: "+381",
    currencyCode: "RSD",
  },
  {
    label: "Seychelles",
    icon: "🇸🇨",
    alpha2Code: "SC",
    alpha3Code: "SYC",
    countryCode: "+248",
    currencyCode: "SCR",
  },
  {
    label: "Sierra Leone",
    icon: "🇸🇱",
    alpha2Code: "SL",
    alpha3Code: "SLE",
    countryCode: "+232",
    currencyCode: "SLL",
  },
  {
    label: "Singapore",
    icon: "🇸🇬",
    alpha2Code: "SG",
    alpha3Code: "SGP",
    countryCode: "+65",
    currencyCode: "SGD",
  },
  {
    label: "Slovakia",
    icon: "🇸🇰",
    alpha2Code: "SK",
    alpha3Code: "SVK",
    countryCode: "+421",
    currencyCode: "EUR",
  },
  {
    label: "Slovenia",
    icon: "🇸🇮",
    alpha2Code: "SI",
    alpha3Code: "SVN",
    countryCode: "+386",
    currencyCode: "EUR",
  },
  {
    label: "Solomon Islands",
    icon: "🇸🇧",
    alpha2Code: "SB",
    alpha3Code: "SLB",
    countryCode: "+677",
    currencyCode: "SBD",
  },
  {
    label: "Somalia",
    icon: "🇸🇴",
    alpha2Code: "SO",
    alpha3Code: "SOM",
    countryCode: "+252",
    currencyCode: "SOS",
  },
  {
    label: "South Africa",
    icon: "🇿🇦",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    countryCode: "+27",
    currencyCode: "ZAR",
  },
  {
    label: "South Sudan",
    icon: "🇸🇸",
    alpha2Code: "SS",
    alpha3Code: "SSD",
    countryCode: "+211",
    currencyCode: "SSP",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    icon: "🇬🇸",
    alpha2Code: "GS",
    alpha3Code: "SGS",
    countryCode: "+500",
    currencyCode: "GBP",
  },
  {
    label: "Spain",
    icon: "🇪🇸",
    alpha2Code: "ES",
    alpha3Code: "ESP",
    countryCode: "+34",
    currencyCode: "EUR",
  },
  {
    label: "Sri Lanka",
    icon: "🇱🇰",
    alpha2Code: "LK",
    alpha3Code: "LKA",
    countryCode: "+94",
    currencyCode: "LKR",
  },
  {
    label: "Sudan",
    icon: "🇸🇩",
    alpha2Code: "SD",
    alpha3Code: "SDN",
    countryCode: "+249",
    currencyCode: "SDG",
  },
  {
    label: "Surilabel",
    icon: "🇸🇷",
    alpha2Code: "SR",
    alpha3Code: "SUR",
    countryCode: "+597",
    currencyCode: "SRD",
  },
  {
    label: "Svalbard and Jan Mayen",
    icon: "🇸🇯",
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    countryCode: "+47",
    currencyCode: "NOK",
  },
  {
    label: "Swaziland",
    icon: "🇸🇿",
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    countryCode: "+268",
    currencyCode: "SZL",
  },
  {
    label: "Sweden",
    icon: "🇸🇪",
    alpha2Code: "SE",
    alpha3Code: "SWE",
    countryCode: "+46",
    currencyCode: "SEK",
  },
  {
    label: "Switzerland",
    icon: "🇨🇭",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    countryCode: "+41",
    currencyCode: "CHF",
  },
  {
    label: "Syrian Arab Republic",
    icon: "🇸🇾",
    alpha2Code: "SY",
    alpha3Code: "SYR",
    countryCode: "+963",
    currencyCode: "SYP",
  },
  {
    label: "Taiwan",
    icon: "🇹🇼",
    alpha2Code: "TW",
    alpha3Code: "TWN",
    countryCode: "+886",
    currencyCode: "TWD",
  },
  {
    label: "Tajikistan",
    icon: "🇹🇯",
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    countryCode: "+992",
    currencyCode: "TJS",
  },
  {
    label: "Tanzania, United Republic of Tanzania",
    icon: "🇹🇿",
    alpha2Code: "TZ",
    alpha3Code: "TZA",
    countryCode: "+255",
    currencyCode: "TZS",
  },
  {
    label: "Thailand",
    icon: "🇹🇭",
    alpha2Code: "TH",
    alpha3Code: "THA",
    countryCode: "+66",
    currencyCode: "THB",
  },
  {
    label: "Timor-Leste",
    icon: "🇹🇱",
    alpha2Code: "TL",
    alpha3Code: "TLS",
    countryCode: "+670",
    currencyCode: "USD",
  },
  {
    label: "Togo",
    icon: "🇹🇬",
    alpha2Code: "TG",
    alpha3Code: "TGO",
    countryCode: "+228",
    currencyCode: "XOF",
  },
  {
    label: "Tokelau",
    icon: "🇹🇰",
    alpha2Code: "TK",
    alpha3Code: "TKL",
    countryCode: "+690",
    currencyCode: "NZD",
  },
  {
    label: "Tonga",
    icon: "🇹🇴",
    alpha2Code: "TO",
    alpha3Code: "TON",
    countryCode: "+676",
    currencyCode: "TOP",
  },
  {
    label: "Trinidad and Tobago",
    icon: "🇹🇹",
    alpha2Code: "TT",
    alpha3Code: "TTO",
    countryCode: "+1868",
    currencyCode: "TTD",
  },
  {
    label: "Tunisia",
    icon: "🇹🇳",
    alpha2Code: "TN",
    alpha3Code: "TUN",
    countryCode: "+216",
    currencyCode: "TND",
  },
  {
    label: "Turkey",
    icon: "🇹🇷",
    alpha2Code: "TR",
    alpha3Code: "TUR",
    countryCode: "+90",
    currencyCode: "TRY",
  },
  {
    label: "Turkmenistan",
    icon: "🇹🇲",
    alpha2Code: "TM",
    alpha3Code: "TKM",
    countryCode: "+993",
    currencyCode: "TMT",
  },
  {
    label: "Turks and Caicos Islands",
    icon: "🇹🇨",
    alpha2Code: "TC",
    alpha3Code: "TCA",
    countryCode: "+1649",
    currencyCode: "USD",
  },
  {
    label: "Tuvalu",
    icon: "🇹🇻",
    alpha2Code: "TV",
    alpha3Code: "TUV",
    countryCode: "+688",
    currencyCode: "AUD",
  },
  {
    label: "Uganda",
    icon: "🇺🇬",
    alpha2Code: "UG",
    alpha3Code: "UGA",
    countryCode: "+256",
    currencyCode: "UGX",
  },
  {
    label: "Ukraine",
    icon: "🇺🇦",
    alpha2Code: "UA",
    alpha3Code: "UKR",
    countryCode: "+380",
    currencyCode: "UAH",
  },
  {
    label: "United Arab Emirates",
    icon: "🇦🇪",
    alpha2Code: "AE",
    alpha3Code: "ARE",
    countryCode: "+971",
    currencyCode: "AED",
  },
  {
    label: "United Kingdom",
    icon: "🇬🇧",
    alpha2Code: "GB",
    alpha3Code: "GBR",
    countryCode: "+44",
    currencyCode: "GBP",
  },
  {
    label: "United States",
    icon: "🇺🇸",
    alpha2Code: "US",
    alpha3Code: "USA",
    countryCode: "+1",
    currencyCode: "USD",
  },
  {
    label: "Uruguay",
    icon: "🇺🇾",
    alpha2Code: "UY",
    alpha3Code: "URY",
    countryCode: "+598",
    currencyCode: "UYU",
  },
  {
    label: "Uzbekistan",
    icon: "🇺🇿",
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    countryCode: "+998",
    currencyCode: "UZS",
  },
  {
    label: "Vanuatu",
    icon: "🇻🇺",
    alpha2Code: "VU",
    alpha3Code: "VUT",
    countryCode: "+678",
    currencyCode: "VUV",
  },
  {
    label: "Venezuela",
    icon: "🇻🇪",
    alpha2Code: "VE",
    alpha3Code: "VEN",
    countryCode: "+58",
    currencyCode: "VEF",
  },
  {
    label: "Vietnam",
    icon: "🇻🇳",
    alpha2Code: "VN",
    alpha3Code: "VNM",
    countryCode: "+84",
    currencyCode: "VND",
  },
  {
    label: "Virgin Islands, British",
    icon: "🇻🇬",
    alpha2Code: "VG",
    alpha3Code: "VGB",
    countryCode: "+1284",
    currencyCode: "USD",
  },
  {
    label: "Virgin Islands, U.S.",
    icon: "🇻🇮",
    alpha2Code: "VI",
    alpha3Code: "VIR",
    countryCode: "+1340",
    currencyCode: "USD",
  },
  {
    label: "Wallis and Futuna",
    icon: "🇼🇫",
    alpha2Code: "WF",
    alpha3Code: "WLF",
    countryCode: "+681",
    currencyCode: "XPF",
  },
  {
    label: "Yemen",
    icon: "🇾🇪",
    alpha2Code: "YE",
    alpha3Code: "YEM",
    countryCode: "+967",
    currencyCode: "YER",
  },
  {
    label: "Zambia",
    icon: "🇿🇲",
    alpha2Code: "ZM",
    alpha3Code: "ZMB",
    countryCode: "+260",
    currencyCode: "ZMW",
  },
  {
    label: "Zimbabwe",
    icon: "🇿🇼",
    alpha2Code: "ZW",
    alpha3Code: "ZWE",
    countryCode: "+263",
    currencyCode: "BWP",
  },
];
