export const JOINN_SUBGRAPH_URI =
  "https://api.studio.thegraph.com/query/68622/bof-aa/version/latest";

export const SUBGRAPH_URI: { [key: number]: string } = {
  // 137: "https://api.studio.thegraph.com/query/98967/joinn-polygon/version/latest", //Obsolete
  137: "https://api.studio.thegraph.com/query/68622/joinn-polygon/version/latest",
  // 80002: "https://api.studio.thegraph.com/query/68622/bof-aa/version/latest", //Obsolete
  80002:
    "https://api.studio.thegraph.com/query/68622/joinn-amoy/version/latest",
  7668: "https://graph-trn-mainnet.bof.xyz/subgraphs/name/bof/vault",
  7672: "https://graph-trn-testnet.bof.xyz/subgraphs/name/bof/vault",
  11155111:
    "https://api.studio.thegraph.com/query/68622/bof-sepolia/version/latest",
  421614:
    "https://api.studio.thegraph.com/query/68622/joinn-arbitrum-sepolia/version/latest",
};

export const ACCEPTED_COUNTRIES = "https://docs.bof.xyz/user-access";
export const FRACTAL_ID = "https://web.fractal.id/";
export const FRACTAL_SECURITY =
  "https://fractal.freshdesk.com/support/solutions/folders/76000009565";
export const FRACTAL_PRIVACY_POLICY =
  "https://app.fractal.id/documents/41a635413a9fd3081492/privacy-policy-v11.pdf";
export const FRACTAL_EMAIL = "support@Fractal.id";

export const SUPPORT_JOINN = "https://t.me/JoinnFinance";
export const TWITTER_JOINN = "https://x.com/JoinnFinance";
export const TELEGRAM_JOINN = "https://t.me/JoinnFinance";
export const TERMS_OF_SERVICE =
  "https://help.joinn.io/hc/en-us/articles/11636081871503-Terms-of-Service";
export const PRIVACY_POLICY =
  "https://help.joinn.io/hc/en-us/articles/11636020293007-Privacy-Policy";
export const CONTACT_US = "https://help.joinn.io/hc/en-us/requests/new";
export const FEED_BACK = "https://help.joinn.io/hc/en-us/requests/new";
export const INFO = "https://help.joinn.io/hc/en-us/";

export const US_TREASURY_BONDS =
  "https://www.ishares.com/uk/individual/en/products/307243/ishares-treasury-bond-0-1yr-ucits-etf";

export const BDC_INCOME =
  "https://www.vaneck.com/us/en/investments/bdc-income-etf-bizd/overview/";

export const AUTOMATED_LENDING = "https://help.joinn.io/hc/en-us/";

export const IMMERSVE_GENERAL_USE =
  "https://immersve.com/general-terms-of-use/";

export const IMMERSVE = "https://immersve.com/";

export const HELP_IMMERSVE = "https://help.joinn.io/hc/en-us/requests/new";

export const SPENDING_ACCOUNT =
  "https://docs.bof.xyz/bof-accounts/spending-account";

export const SWARM_AUDIT =
  "https://docs.swarm.com/reference/smart-contract-audit";

export const AAVE_AUDIT =
  "https://docs.aave.com/developers/deployed-contracts/security-and-audits";

export const QUICK_SWAP_AUDIT =
  "https://docs.quickswap.exchange/technical-reference/smart-contracts";

export const STOLEN_CARD =
  "https://help.joinn.io/hc/en-us/articles/11511909378191-Stolen-Card";

export const DISPUTE_TRANSACTION =
  "https://help.joinn.io/hc/en-us/articles/11522416017807-Transaction-Dispute-or-Chargeback";

export const MASTERCARD_FAQ =
  "https://help.joinn.io/hc/en-us/sections/11511709724175-Mastercard-FAQ-s";
