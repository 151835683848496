import { Text } from "@/components";
import { Skeleton } from "@/components/ui";
import { cn } from "@/lib/utils";

type FrontCardProps = {
  balance?: number | string;
  panLast4?: string;
  isHidden?: boolean;
  isConnected: boolean;
  isLoading?: boolean;
};

export function FrontCard(props: Readonly<FrontCardProps>) {
  const { balance, panLast4, isHidden, isConnected, isLoading } = props;
  return (
    <>
      <div>
        <Text variant="label" size="sm">
          Balance
        </Text>
        <div className="relative">
          <Skeleton
            className={cn(
              "absolute hidden h-full w-full bg-accent-foreground/25",
              isLoading && "block",
            )}
          />
          <Text className={cn("visible font-medium", isLoading && "invisible")}>
            {isConnected && !isNaN(Number(balance))
              ? Number(balance).toFixed(2)
              : "**.**"}{" "}
            USDC
          </Text>
        </div>
      </div>
      {!isHidden && (
        <div className="relative">
          <Skeleton
            className={cn(
              "absolute hidden h-full w-full bg-accent-foreground/25",
              isLoading && "block",
            )}
          />
          <Text className={cn("visible font-medium", isLoading && "invisible")}>
            {panLast4 ? ` **** ${panLast4}` : "**** ****"}
          </Text>
        </div>
      )}
    </>
  );
}
