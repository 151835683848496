import { useStatsQuery } from "@/redux/adminPanel/adminPanel.api";
import { Card } from "../ui";
import { DataTile } from "./dataTile";

const overviewData = [
  { title: "Current Total Users", value: 542 },
  { title: "Current Linked Wallets", value: 542 },
  { title: "Total User Site Visits", value: 56 },
  { title: "Total Sponsored Gas Used", value: 450, currency: "MATIC" },
  { title: "PayMaster Wallet Balance", value: 950, currency: "MATIC" },
  { title: "Total Verified Users", value: 450, currency: "MATIC" },
  { title: "Current TVL Earn Accounts", value: 234678.94, currency: "USDC" },
  {
    title: "Current TVL Spending Accounts",
    value: 234678.94,
    currency: "USDC",
  },
  { title: "Total BoF Revenue", value: 34678.94, currency: "USDC" },
];

export function DataSection() {
  const { data: stats } = useStatsQuery();

  return (
    <Card radius="md" size="md" className="grid grid-cols-4 gap-3">
      {stats ? (
        <>
          <DataTile title="Total Users" value={stats.totalUsers} />
          <DataTile
            title="Total Verified Users"
            value={stats.totalVerifiedUsers}
          />
          <DataTile
            title="Total Linked Wallets"
            value={stats.totalLinkedWallets}
          />
          {stats.paymasterBalances.map((paymaster) => (
            <DataTile
              key={paymaster.chainId}
              title={`Paymaster Balance (${paymaster.chainId})`}
              value={paymaster.balance}
            />
          ))}
        </>
      ) : null}
    </Card>
  );
}
