import { Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { useAccounts, useFormatToken } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { JoinnHistory } from "@/services/interfaces";

export function HistorySharesDetails({
  shares,
}: Readonly<{ shares: JoinnHistory }>) {
  const { closeModal } = useModalState();
  const { adjustSignAndFormat } = useFormatToken();
  const {
    getContractName,
    getContractFromName,
    allAccounts,
    vaultsAndSpendingAccount,
  } = useAccounts();

  const amountFrom =
    Number(shares.fromShares) * Number(shares.fromPricePerShare);
  return (
    <div className="rounded-b-[inherit] max-md:p-4">
      <Card className="my-2 min-w-full text-nowrap" radius="base" size="md">
        <Text className="mb-5">{getContractName(shares.from)}</Text>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Shares
          </Text>
          <div>
            {"-"}
            {adjustSignAndFormat(shares.fromShares)}
          </div>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Price per share
          </Text>
          <div>{adjustSignAndFormat(shares.fromPricePerShare)}</div>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Amount
          </Text>
          <div>
            {"-"}
            {adjustSignAndFormat(shares.amount)} USDC
          </div>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
      </Card>
      <Card
        className="my-2 mt-4 min-w-full text-nowrap"
        radius="base"
        size="md"
      >
        <Text className="mb-5">{getContractName(shares.to)}</Text>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Shares
          </Text>
          <div>{adjustSignAndFormat(shares.toShares)}</div>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Price per share
          </Text>
          <div>{adjustSignAndFormat(shares.toPricePerShare)}</div>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Amount
          </Text>
          <div>{adjustSignAndFormat(shares.amount)} USDC</div>
        </div>
      </Card>
      <Separator className="mx-[-20px] my-2 w-auto" />
      <div className="flex justify-end">
        <Button
          type="button"
          variant="primary"
          size="md"
          onClick={closeModal}
          className="gap-0 capitalize"
        >
          Okay
        </Button>
      </div>
    </div>
  );
}
