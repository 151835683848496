import { useLazyBalancesQuery } from "@/redux/balance/balance.api";
import { useLazyInfoQuery } from "@/redux/info/info.api";
import {
  useLazyUserProfileQuery,
  useLazyUserWalletsQuery,
} from "@/redux/user/user.api";
import { useUser } from "@/redux/user/user.slice";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useAccount } from "wagmi";

export function useDashboard() {
  const { chainId, address } = useAccount();
  const { lastLoggedInWallet, isUserLoggedIn } = useUser();

  // Lazy queries
  const [
    fetchInfo,
    { data: info, isLoading: isInfoLoading, isSuccess: isInfoFetched },
  ] = useLazyInfoQuery();
  const [
    fetchProfile,
    {
      data: profile,
      isLoading: isUserProfileLoading,
      isSuccess: isUserProfileFetched,
    },
  ] = useLazyUserProfileQuery();
  const [
    fetchWallet,
    {
      data: wallet,
      isLoading: isUserWalletsLoading,
      isSuccess: isUserWalletsFetched,
    },
  ] = useLazyUserWalletsQuery();
  const [
    fetchBalances,
    {
      data: balances,
      isLoading: isBalancesLoading,
      isSuccess: isBalancesFetched,
      isError: isBalancesError,
      isFetching: isBalancesFetching,
    },
  ] = useLazyBalancesQuery();

  const isAddressLinkedAccount = useMemo(() => {
    return !isEmpty(
      profile?.linkedAccounts?.find(
        (linkedAccount) =>
          linkedAccount.address.toLowerCase() === address?.toLowerCase(),
      ),
    );
  }, [profile, address]);

  function initialize() {
    if (chainId && !isInfoFetched) fetchInfo(chainId);
    if (!isUserProfileFetched) fetchProfile();
  }

  useEffect(() => {
    if (chainId && isUserLoggedIn) {
      initialize();
    }
  }, [chainId, isInfoFetched, isUserProfileFetched, isUserLoggedIn]);

  useEffect(() => {
    if (profile && lastLoggedInWallet && !isUserWalletsFetched) {
      fetchWallet({
        walletName: profile?.walletName || {},
        address: lastLoggedInWallet,
        chainId,
        linkedAccounts: profile?.linkedAccounts,
      });
    }
  }, [profile, lastLoggedInWallet, chainId, isUserWalletsFetched]);

  useEffect(() => {
    if (wallet && info && !isBalancesFetched) {
      fetchBalances({
        vaults: info?.vaults || [],
        joinnWalletAdddress: wallet?.joinnWalletAddress ?? "",
        chainId,
        userWallets: wallet?.joinnWallets || {},
      });
    }
  }, [wallet, info, chainId, isBalancesFetched]);

  return {
    info,
    userProfile: profile,
    userWallets: wallet,
    balances,
    isInfoLoading,
    isInfoFetched,
    isUserProfileLoading,
    isUserProfileFetched,
    isUserWalletsLoading,
    isUserWalletsFetched,
    isBalancesFetching,
    isBalancesLoading,
    isBalancesFetched,
    isBalancesError,
    isAddressLinkedAccount,
    refetch: initialize,
    fetchProfile,
  };
}
