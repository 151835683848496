import { isNaN, isNil } from "lodash";

export function amountFormatter(
  amount: string | number | null | undefined,
  decimals = 2,
) {
  if (isNil(amount) || isNaN(Number(amount))) return "n/a";
  amount = Number(amount);
  return amount.toFixed(decimals);
}
